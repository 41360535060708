import { Modal, Box, TextField, Button, Typography, Switch, MenuItem, Select } from "@mui/material";
import { Formik, Form } from "formik";
import usePost from "hooks/usePost";

export default function UpdateBillsModal({ open, handleClose, data, refetch }) {
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const initialValues = {
        payment_date: formatDate(data?.payment_date),
        paid_date: formatDate(data?.paid_date),
        overdue_date: formatDate(data?.overdue_date),
        next_date: formatDate(data?.next_date),
        amount: data?.amount || '',
        is_visible: data?.is_visible ? 'true' : 'false',
        is_loan_rent: data?.is_loan_rent ? 'true' : 'false',
        is_selected: data?.is_selected ? 'true' : 'false',
        is_disburse: data?.is_disburse ? 'true' : 'false',
        is_auto: data?.is_auto || false,
        status: data?.status || ''
    };

    const { mutate } = usePost(`/bill/update_bill/admin/${data?._id}`, 'patch', {
        onSuccess: (response) => {
            alert('Bill Updated');
            refetch()
        },
        onError: (error) => {
            alert('Error updating bill');
            refetch()
        },
    });

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Update Monthly Bill
                </Typography>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        // console.log(values);
                        mutate(values);
                        setSubmitting(false);
                        handleClose();
                    }}
                >
                    {({ values, handleChange, handleBlur, touched, errors, isSubmitting }) => (
                        <Form>
                            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", marginTop: "1rem" }}>
                                <div style={{ flex: '1' }}>
                                    <p>Payment Date</p>
                                    <TextField
                                        fullWidth
                                        id="payment_date"
                                        name="payment_date"
                                        label=""
                                        type="date"
                                        value={values.payment_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.payment_date && Boolean(errors.payment_date)}
                                        helperText={touched.payment_date && errors.payment_date}
                                    />
                                </div>
                                <div style={{ flex: '1' }}>
                                    <p>Paid Date</p>
                                    <TextField
                                        fullWidth
                                        id="paid_date"
                                        name="paid_date"
                                        label=""
                                        type="date"
                                        value={values.paid_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.paid_date && Boolean(errors.paid_date)}
                                        helperText={touched.paid_date && errors.paid_date}
                                    />
                                </div>
                                <div style={{ flex: '1' }}>
                                    <p>Overdue Date</p>
                                    <TextField
                                        fullWidth
                                        id="overdue_date"
                                        name="overdue_date"
                                        label=""
                                        type="date"
                                        value={values.overdue_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.overdue_date && Boolean(errors.overdue_date)}
                                        helperText={touched.overdue_date && errors.overdue_date}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                                <div style={{ flex: '1' }}>
                                    <p>Next Date</p>
                                    <TextField
                                        fullWidth
                                        id="next_date"
                                        name="next_date"
                                        label=""
                                        type="date"
                                        value={values.next_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.next_date && Boolean(errors.next_date)}
                                        helperText={touched.next_date && errors.next_date}
                                    />
                                </div>
                                <div style={{ flex: '1' }}>
                                    <p>Amount</p>
                                    <TextField
                                        fullWidth
                                        id="amount"
                                        name="amount"
                                        label=""
                                        type="text"
                                        value={values.amount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.amount && Boolean(errors.amount)}
                                        helperText={touched.amount && errors.amount}
                                    />
                                </div>
                                <div style={{ flex: '1' }}>
                                    <p>Status</p>
                                    <Select
                                        labelId="status-label"
                                        id="status"
                                        name="status"
                                        value={values.status}
                                        label="Status"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.status && Boolean(errors.status)}
                                        style={{ width: '100%' }}
                                    >
                                        <MenuItem value="paid">Paid</MenuItem>
                                        <MenuItem value="unpaid">UnPaid</MenuItem>
                                    </Select>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '3rem', gap: '2rem', marginTop: '3rem' }}>
                                <div className="form-toggle">
                                    <h6>Visible</h6>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p>Yes</p>
                                        <Switch
                                            id="is_visible"
                                            name="is_visible"
                                            checked={values.is_visible === 'true'}
                                            onChange={(e) => handleChange({ target: { name: 'is_visible', value: e.target.checked ? 'true' : 'false' } })}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                        <p>No</p>
                                    </div>
                                </div>
                                <div className="form-toggle">
                                    <h6>Loan</h6>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p>Yes</p>
                                        <Switch
                                            id="is_loan_rent"
                                            name="is_loan_rent"
                                            checked={values.is_loan_rent === 'true'}
                                            onChange={(e) => handleChange({ target: { name: 'is_loan_rent', value: e.target.checked ? 'true' : 'false' } })}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                        <p>No</p>
                                    </div>
                                </div>
                                <div className="form-toggle">
                                    <h6>Selected</h6>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p>Yes</p>
                                        <Switch
                                            id="is_selected"
                                            name="is_selected"
                                            checked={values.is_selected === 'true'}
                                            onChange={(e) => handleChange({ target: { name: 'is_selected', value: e.target.checked ? 'true' : 'false' } })}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                        <p>No</p>
                                    </div>
                                </div>
                                <div className="form-toggle">
                                    <h6>Disbursement</h6>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p>Yes</p>
                                        <Switch
                                            id="is_disburse"
                                            name="is_disburse"
                                            checked={values.is_disburse === 'true'}
                                            onChange={(e) => handleChange({ target: { name: 'is_disburse', value: e.target.checked ? 'true' : 'false' } })}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                        <p>No</p>
                                    </div>
                                </div>
                                <div className="form-toggle">
                                    <h6>Is Auto</h6>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p>Yes</p>
                                        <Switch
                                            id="is_auto"
                                            name="is_auto"
                                            checked={values.is_auto}
                                            onChange={handleChange}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                        <p>No</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '3rem' }}>
                                <Button type="submit" variant="outlined" color="primary" disabled={isSubmitting}>
                                    Update Bill
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
}
