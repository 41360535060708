import { propertyServiceApi } from "app/apis/propertyService";

export const monthlyRentApiSlice = propertyServiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserMonthlyRentApplication: builder.query({
      query: (id) => `/application/${id}`,
    }),
    getUserMonthlyRentInvoice: builder.query({
      query: (liveizyId) => `/loan-record/single/${liveizyId}`,
    }),
  }),
});

export const {
  useGetUserMonthlyRentApplicationQuery,
  useGetUserMonthlyRentInvoiceQuery,
} = monthlyRentApiSlice;
