import { Button, Container, styled } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { AiFillFileText } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { izypromanService } from "../../../../../apis";
import TitleUnderline from "../../../../../components/parts/TitleUnderline";
import { useGetUserMonthlyRentApplicationQuery } from "../monthlyRentApiSlice";
import BackComp from "components/parts/BackComp";

const Card = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto;
  background: white;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
  border-radius: 29.4316px;
  padding: 10px;
  margin-bottom: 40px;
`;

const VerifyStageTwo = () => {
  const { liveizy_id } = useParams();
  const navigate = useNavigate();

  const { data: applicationData } =
    useGetUserMonthlyRentApplicationQuery(liveizy_id);

  const handleActivate = async () => {
    let payload = { monthly_rent_status_stage_2: `accepted` };
    izypromanService()
      .patch(
        `/application/rent-apply-status-stage-2/admin/${liveizy_id}`,
        payload
      )
      .then((res) => {
        alert(res?.data?.message || "status updated");
        return navigate(`/property/monthly-rent`);
      })
      .catch((error) => {
        alert(error?.response?.data?.error || error?.message);
      });
  };

  return (
    <Container>
      <BackComp />
      {/* <section>
        <TitleUnderline title={"Payment Made"} />
        <div style={{ display: "flex" }}>
          <h4>{loandetails?.apartment_id}</h4>
          <Card>
            <SubtitleAndSubtext
              subtitle={"Subscription Fee"}
              subtext={toCurrency(loandetails?.subscription_fee)}
            />
          </Card>
          <Card>
            <SubtitleAndSubtext
              subtitle={"Security Deposit"}
              subtext={toCurrency(loandetails?.security_deposit)}
            />
          </Card>
        </div>
      </section> */}
      <section>
        <TitleUnderline title={"Signed agreement by user"} />
        <div style={{ maxWidth: "250px", margin: "0 auto" }}>
          <Card>
            <a
              href={`${applicationData?.payload[0]?.agreement_doc}`}
              style={{
                width: "220px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
              target="_blank"
              rel="noreferrer"
            >
              <AiFillFileText fontSize={"50px"} />
              <p>Signed Agreement</p>
            </a>
          </Card>
        </div>
      </section>
      {applicationData?.payload[0]?.monthly_rent_status_stage_2 !==
      "accepted" ? (
        <Stack gap={2}>
          <Button variant="contained" onClick={handleActivate} type="button">
            Verify Stage two
          </Button>
        </Stack>
      ) : null}
    </Container>
  );
};

export default VerifyStageTwo;
