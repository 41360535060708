import { styled } from "@mui/material";
import React from "react";

const Container = styled("div")`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  gap: 7px;
  margin-bottom: 20px;
`;

const SidebarLink = ({ logo, text }) => {
  return (
    <Container>
      <div>{logo}</div>
      <div>{text}</div>
    </Container>
  );
};

export default SidebarLink;
