import { styled } from '@mui/material'
import React from 'react'

const Container = styled("div")`
    /* padding: 2px 0; */
    width: 100%;
    border-bottom: 1px solid rgba(3, 21, 34, 0.5);
    margin-bottom: 20px;
`

const TitleUnderline = ({title}) => {
  return (
      <Container>
          <p><strong>{title}</strong></p>
    </Container>
  )
}

export default TitleUnderline