import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { authService } from "../../../apis";

function getFullName(params) {
  return `${params.row.first_name || ""} ${params.row.last_name || ""}`;
}

export const usersTableColumns = [
  {
    field: "fullName",
    headerName: <strong>Full Name</strong>,
    width: 280,
    flex: 1,
    valueGetter: getFullName,
  },
  { field: "liveizy_id", headerName: <strong>LID</strong>, width: 90 },
  { field: "email", headerName: <strong>Email Address</strong>, width: 200 },
  {
    field: "phone",
    headerName: <strong>Mobile Number</strong>,
    width: 140,
  },
  { field: "roles", headerName: <strong>Role</strong>, width: 160 },
  {
    field: "is_email_verified",
    headerName: <strong>Verified</strong>,
    width: 90,
    renderCell: (params) => {
      if (params.row.is_email_verified) {
        return (
          <Box
            color={"#25CC22"}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            gap={"5px"}
            fontSize="12px"
          >
            <VerifiedUserIcon fontSize="11px" />
            <Typography variant="p">Verified</Typography>
          </Box>
        );
      }
      return (
        <Button
          color="primary"
          variant="outlined"
          sx={{ fontSize: "8px", padding: "5px" }}
          onClick={() => verifyUser(params.row.liveizy_id)}
        >
          Verify User
        </Button>
      );
    },
  },
  {
    field: "view",
    headerName: "",
    width: 90,
    renderCell: (params) => {
      return (
        <Link to={`/users/view/${params.row.liveizy_id}`}>
          <Button
            color="primary"
            sx={{ background: "#10609F", fontSize: "10px", color: "white" }}
          >
            View
          </Button>
        </Link>
      );
    },
  },

  //   {
  //     field: "fullName",
  //     headerName: "Full name",
  //     description: "This column has a value getter and is not sortable.",
  //     sortable: false,
  //     width: 160,
  //     valueGetter: (params) =>
  //       `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  //   },
];

const verifyUser = (lid) => {
  authService()
    .put(`/admin/users/${lid}/verify-email `)
    .then((res) => {
      alert(`${lid} is verified`);
      window.location.reload();
    })
    .catch((error) => {
      alert(error);
    });
};
