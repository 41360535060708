import { TbCurrencyNaira } from "react-icons/tb";
import { styled } from "@mui/material";
import React from "react";

const InputWrapper = styled("div")`
  position: relative;
  margin-bottom: 20px;
`;

const StyledInput = styled("input")`
  padding: 10px 10px 10px 30px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f6f6f6;
  box-shadow: inset 0px 0px 10px #d9d9d9;

  &:focus {
    outline: none;
    box-shadow: inset 0px 0px 10px #b3b3b3;
  }
`;

const Label = styled("label")`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const IconWrapper = styled("div")`
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  left: 10px;
`;

const CustomInputAndLogoField = ({ label = "Label", ...props }) => {
  return (
    <InputWrapper>
      <Label>{label}</Label>

      <IconWrapper>
        <TbCurrencyNaira />
      </IconWrapper>

      <StyledInput {...props} type={"number"} />
    </InputWrapper>
  );
};

export default CustomInputAndLogoField;
