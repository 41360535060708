import React from "react";
import { Card } from "../styles/propertyStyles";
import { useNavigate } from "react-router-dom";
import styles from "../styles/property.module.css";
import { Button } from "@mui/material";
import DataTable from "components/parts/DataTable";


function getDate(params) {
  return `${new Date(params.row.createdAt).toLocaleDateString() || `-`}`
}

const StageOne = ({ applicants }) => {
  const navigate = useNavigate();

  const handleView = (liveizy_id) => {
    navigate(`/property/monthly-rent/verify-stageone/${liveizy_id}`);
  };

  const usersTableColumns = [
    {
      field: 'fullName',
      headerName: "User Name",
      width: 300,
      headerClassName: 'bold-header',
      valueGetter: (params) => `${params.row?.userDetails?.first_name || ''} ${params.row?.userDetails?.last_name || ''}`
    },
    { field: 'laid', headerName: "Apartment ID", width: 300, headerClassName: 'bold-header' },
    { field: 'rent_range', headerName: "Rent Range", width: 200, headerClassName: 'bold-header' },
    { field: 'lid', headerName: "Tenant LID", width: 200, headerClassName: 'bold-header' },
    { field: 'createdAt', headerName: "Date Applied", width: 200, valueGetter: getDate, headerClassName: 'bold-header' },
    { field: 'monthly_rent_status_stage_1', headerName: "Status", width: 200, headerClassName: 'bold-header' },
    {
      field: "view",
      headerName: "",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            color="primary"
            sx={{ background: "#10609F", fontSize: "10px", color: "white" }}
            onClick={() => handleView(params.row.lid)}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <p className={styles.awaitingListing}> Accounts with monthly rent </p>
        <h1 className={styles.awaitingListingCount}>{applicants.length}</h1>
      </Card>

      <div style={{ margin: '50px 0' }}>
        <DataTable
          autoHeight
          rows={applicants}
          columns={usersTableColumns}
          getRowId={(row) => row._id}
        />
      </div>

      {/* <AwaitingListBody>
        <table className={styles.awaitingListTable}>
          <thead>
            <tr className={styles.tableRow}>
              <th>User Name</th>
              <th>Apartment ID</th>
              <th>Rent Range</th>
              <th>Tenant LID</th>
              <th>Date Applied</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          {applicants.map((rent) => (
            <tbody>
              <tr key="{rent.index}">
                <td>{rent.fullName || `-`}</td>
                <td>{rent.apartment_id || `-`}</td>
                <td>{rent.rent_range || `-`}</td>
                <td>{rent.lid || `-`}</td>
                <td>{new Date(rent.createdAt).toLocaleDateString()}</td>
                <td>{rent.monthly_rent_status_stage_1}</td>
                <td>
                  <button onClick={() => handleView(rent.lid)}>View</button>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </AwaitingListBody> */}
    </>
  );
};

export default StageOne;
