import { styled } from "@mui/material";
import React from "react";
import SubtitleAndSubtext from "../parts/SubtitleAndSubtext";
import DetailsCardContainer from "./DetailsCardContainer";
import defaultProfileImage from "../../mock data/mockDp.png";

const ProfilePx = styled("div")`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Details = styled("div")`
  display: flex;
  justify-content: flex-start;
`;

const Column = styled("div")`
  margin-right: 50px;
`;

const PersonalDetails = ({
  surname,
  firstName,
  otherName,
  sex,
  dateOfBirth,
  StateOfOrigin,
  lga,
  email,
  altEmail,
  mobile,
  altMobile,
  profilePx,
  bvn,
}) => {
  return (
    <DetailsCardContainer>
      {/* Profile pic and BVN */}
      <div className="position-column-center">
        <ProfilePx>
          <img
            src={profilePx ? profilePx : defaultProfileImage}
            alt="profile pic"
          />
        </ProfilePx>
        <p>BVN: {bvn}</p>
      </div>
      {/* Details */}
      <Details>
        <Column>
          <SubtitleAndSubtext subtitle={"Surname"} subtext={surname} />
          <SubtitleAndSubtext
            subtitle={"Date Of Birth"}
            subtext={dateOfBirth}
          />
          <SubtitleAndSubtext
            subtitle={"Alternate Email Address"}
            subtext={altEmail}
          />
        </Column>
        <Column>
          <SubtitleAndSubtext subtitle={"First Name"} subtext={firstName} />
          <SubtitleAndSubtext
            subtitle={"State Of Origin"}
            subtext={StateOfOrigin}
          />
          <SubtitleAndSubtext subtitle={"Mobile Number"} subtext={mobile} />
        </Column>
        <Column>
          <SubtitleAndSubtext subtitle={"Other Name"} subtext={otherName} />
          <SubtitleAndSubtext
            subtitle={"Local Government Area"}
            subtext={lga}
          />
          <SubtitleAndSubtext
            subtitle={"Alternate Mobile Number"}
            subtext={altMobile}
          />
        </Column>
        <Column>
          <SubtitleAndSubtext subtitle={"Sex"} subtext={sex} />
          <SubtitleAndSubtext subtitle={"Email"} subtext={email} />
        </Column>
      </Details>
    </DetailsCardContainer>
  );
};

export default PersonalDetails;
