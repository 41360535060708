import { styled } from "@mui/material";
import React from "react";

const CustomConfigInput = ({ label, small }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: `${small ? "center" : "flex-start"}`,
        gap: "0.45rem",
      }}
    >
      {label ? (
        <label
          htmlFor={label}
          style={{ fontSize: `${small ? "0.75rem" : "0.85rem"}` }}
        >
          {label}
        </label>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.7rem",
        }}
      >
        {small ? <div style={{ cursor: "pointer" }}>+</div> : null}
        <CustomInput type="text" small={small} />
        <p>%</p>
      </div>
    </div>
  );
};

export default CustomConfigInput;

const CustomInput = styled("input")`
  border-radius: 25px;
  padding: 5px;
  font-size: 0.95rem;
  max-width: ${(props) => (props.small ? "50px" : "80px")};
`;
