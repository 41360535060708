import React, { useState, useEffect } from "react";
import { useAppContext } from "../../context/context";
import { Container, Card, AwaitingListBody } from "./propertyStyles";
// import { izypromanService } from "../../apis";
import { Link, useNavigate } from "react-router-dom";
import styles from "./property.module.css";

const Property = () => {
  return (
    <Container>
      <Link to="/property/monthly-rent">Monthly rent</Link>
      {/* <Link to="/property/monthly-rent/verify-stagetwo">Monthly two</Link> */}
    </Container>
  );
};

export default Property;
