import { Box, Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { authService, izypromanService } from "../../apis";
import PropertyAccordion from "../../components/accordions/PropertyAccordion";
import EmploymentDetails from "../../components/cards/EmploymentDetails";
import PersonalDetails from "../../components/cards/PersonalDetails";
import TenancyDetails from "../../components/cards/TenancyDetails";
import BackComp from "../../components/parts/BackComp";
import TitleUnderline from "../../components/parts/TitleUnderline";
import { useAppContext } from "../../context/context";
import { toCurrency } from "../../utils/misc";
import PropertyComponent from "./components/PropertyComponent";

const LANDLORD = "Landlord";
const AGENT = "Agent";

const UserView = () => {
  const { setNavState } = useAppContext();

  const [userDetails, setUserDetails] = useState({
    profileInfo: {},
    otherInfo: {},
  });
  const { liveizy_id } = useParams();
  const navigate = useNavigate();

  const deleteUser = () => {
    authService()
      .delete(`/admin/users/${liveizy_id}`)
      .then((res) => {
        alert(`${liveizy_id} deleted successfully`);
        navigate("/users");
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    const getUserDetails = () => {
      const userDetails = authService()
        .get(`/user/get/details/${liveizy_id}`)
        .then((response) => {
          const { data } = response.data;
          setUserDetails({
            profileInfo: data.profile,
            otherInfo: data.other_information,
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    };
    getUserDetails();
  }, [liveizy_id]);
  useEffect(() => {
    setNavState("USER INFO");
  }, []);

  // Get tenancy details

  return (
    <div style={{ width: "100%" }}>
      <BackComp />
      {/* Personal Details */}
      <TitleUnderline title="Personal Details" />
      <PersonalDetails
        bvn={userDetails.profileInfo?.bvn}
        profilePx={userDetails.profileInfo?.profile_image}
        surname={userDetails.profileInfo?.last_name}
        firstName={userDetails.profileInfo?.first_name}
        otherName={userDetails.otherInfo?.other_name}
        sex={userDetails.profileInfo?.gender}
        dateOfBirth={userDetails.profileInfo?.date_of_birth}
        StateOfOrigin={userDetails.profileInfo?.state_of_origin}
        lga={userDetails.profileInfo?.local_governement}
        email={userDetails.profileInfo?.email}
        altEmail={userDetails.profileInfo?.alt_email}
        mobile={userDetails.profileInfo?.phone}
        altMobile={userDetails.profileInfo?.alt_phone}
      />
      {/* Employment Details */}
      <TitleUnderline title="Employment Details" />
      <EmploymentDetails
        employmentStatus={userDetails.otherInfo?.employment_status}
        officeContactPersonName={
          userDetails.otherInfo?.office_contact_person_name
        }
        employerName={userDetails.otherInfo?.employer_name}
        officeContactPersonNumber={
          userDetails.otherInfo?.office_contact_person_phone_number
        }
        officeAddress={userDetails.otherInfo?.office_address}
        monthlyIncome={userDetails.otherInfo?.monthly_income}
      />
      {/* Tenancy Details */}
      <TitleUnderline title="Tenancy Details" />
      <TenancyDetails lid={userDetails.profileInfo?.liveizy_id} />

      {/* For Landlords and Agents */}
      {userDetails.profileInfo?.role &&
        userDetails.profileInfo?.role.includes(LANDLORD || AGENT) && (
          <PropertyComponent lid={userDetails.profileInfo?.liveizy_id} />
        )}

      <Box>
        <Button variant="contained" onClick={deleteUser}>
          Delete User
        </Button>
      </Box>
    </div>
  );
};

export default UserView;
