export const ROUTES = {
  superAdmin: "/estate/super-admin/:id",
  estateLists: "/estate/lists",
  dashboard: "/dashboard",
  property: "/property",
  addAdmin: "/add-admin",
  courses: "/courses",
  users: "/users",
  payments: "/payments",
  plans: "/plans",
  login: "/login",
  index: "/",
};
