import { styled } from '@mui/material'
import React from 'react'

const Container = styled("div")`
    margin-bottom: 30px;
    text-overflow: ellipsis;
`

const SubtitleAndSubtext = ({subtitle, subtext}) => {
  return (
      <Container>
          <p style={{marginBottom:"2px", fontWeight:300, color:"gray"}}>{subtitle}</p>
          <small>{subtext}</small>
    </Container>
  )
}

export default SubtitleAndSubtext