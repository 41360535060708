/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { styled } from "@mui/material";
import { useAppContext } from "context/context";
import React from "react";
import { GiWallet } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/liveizy-logo-dashboard.png";
import SidebarDropdown from "./components/SidebarDropdown";
import SidebarLink from "./components/SidebarLink";
import { sidebarLinks } from "./sidebarLinks";

const Container = styled("div")`
  width: 100%;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #052137;
  padding: 20px;
  width: 100vh;
  position: sticky;
  min-height: 100vh;
`;

const Logo = styled("div")`
  max-width: 150px;
  max-height: 50px;
  margin-bottom: 40px;
  img {
    width: 100%;
    height: 100%;
  }
`;

const wallet = {
  key: 4,
  logo: <GiWallet />,
  name: "Wallet",
  subLinks: [
    {
      id: 1,
      name: "izyPay",
      link: "/wallet/izypay",
    },
    {
      id: 2,
      name: "izyInvest",
      link: "/wallet/izyInvest",
    },
    {
      id: 3,
      name: "referral",
      link: "/wallet/referral",
    },
  ],
  permittedRoles: ["LiveizySuperAdmin"],
};

const Sidebar = () => {
  const { user, navState, setNavState } = useAppContext();

  return (
    <Container>
      <Logo>
        <img src={logo} alt="Liveizy Logo" />
      </Logo>
      <nav className="">
        {sidebarLinks.map((nav) => {
          const { key, logo, name, permittedRoles, link } = nav;

          if (user?.roles?.find((role) => permittedRoles?.includes(role))) {
            return (
              <NavLink to={link} key={key}>
                <SidebarLink logo={logo} text={name} />
              </NavLink>
            );
          }
        })}
        <SidebarDropdown item={wallet} />
      </nav>
    </Container>
  );
};

export default Sidebar;
