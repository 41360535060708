import React, { useState, useEffect } from "react";
import { Container, AwaitingListBody } from "../../../propertyStyles";
import { authService, izypromanService } from "../../../../../apis";
import styles from "../styles/property.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillFileText } from "react-icons/ai";
import { Button, Dialog } from "@mui/material";
import { useGetUserMonthlyRentApplicationQuery } from "../monthlyRentApiSlice";
import { useGetUserDetailsQuery } from "features/auth/authApiSlice";
import BackComp from "components/parts/BackComp";

const REJECTED = "rejected";
const REVIEW = "review";
const VERIFIED = "verified";

let userApplication;

const VerifyStageOneView = () => {
  const { liveizy_id } = useParams();
  const navigate = useNavigate();

  const { data: applicationData } =
    useGetUserMonthlyRentApplicationQuery(liveizy_id);
  const { data: userData } = useGetUserDetailsQuery(liveizy_id);
  const [apartmentDetails, setApartmentDetails] = useState(null);
  const [ownerDetails, setOwnerDetails] = useState("");
  const [managerDetails, setManagerDetails] = useState("");
  const [reviewMessage, setReviewMessage] = useState("");
  const [contributionFee, setcontributionFee] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openFeeModal, setOpenFeeModal] = useState(false);

  const handleSetModalVisibility = () => setOpenModal(!openModal);
  const handleOpenModal = () => setOpenFeeModal(true);
  const handleCloseModal = () => setOpenFeeModal(false);

  const handleAction = async (action) => {
    let payload = { monthly_rent_status_stage_1: `${action}` };
    if (action === REVIEW  || action === VERIFIED) {
      if (reviewMessage) {
        payload = {
          monthly_rent_status_stage_1: `${action}`,
          review_stage_1: reviewMessage ,
        };
      };
      if (contributionFee) {
        payload = {
          monthly_rent_status_stage_1: `${action}`,
          contribution_fee: contributionFee ,
        };
      };
    } else {
      payload = { monthly_rent_status_stage_1: `${action}` };
    }
    await izypromanService()
      .patch(
        `/application/rent-apply-status-stage-1/admin/${liveizy_id}`,
        payload
      )
      .then((res) => {
        handleSetModalVisibility();
        handleCloseModal()
        alert(res?.data?.message || "status updated");
        return navigate(`/property/monthly-rent`);
      })
      .catch((error) => {
        alert(error?.response?.data?.error || error?.message);
      });
  };

  useEffect(() => {
    if (applicationData?.payload.length > 0) {
      userApplication = applicationData.payload[0];
      if (!userApplication.laid) return;
      izypromanService()
        .get(`/apartment/show/${userApplication.laid}`)
        .then((res) => {
          const data = res.data.payload;
          setApartmentDetails(data);
          if (data.owner) {
            authService()
              .get(`/user/get/details/${data.owner}`)
              .then((res) => setOwnerDetails(res.data.data));
          }

          if (data.property_manager) {
            authService()
              .get(`/user/get/details/${data.property_manager}`)
              .then((res) => setManagerDetails(res.data.data));
          }
        });
    }
  }, [applicationData]);

  return (
    <Container>
      <BackComp />
      <Dialog open={openModal} onClose={handleSetModalVisibility}>
        <div
          style={{
            padding: "2rem",
            width: "400px",
            display: "grid",
            gap: "1rem",
          }}
        >
          <p>Review Message</p>
          <input
            value={reviewMessage}
            onChange={(e) => setReviewMessage(e.target.value)}
          />
          <Button
            variant="contained"
            type="button"
            onClick={() => handleAction(REVIEW)}
          >
            Submit Review
          </Button>
        </div>
      </Dialog>
      <Dialog open={openFeeModal} onClose={handleCloseModal}>
        <div
          style={{
            padding: "2rem",
            width: "400px",
            display: "grid",
            gap: "1rem",
          }}
        >
          <p>Add Contribution Fee</p>
          <textarea
            value={contributionFee}
            onChange={(e) => setcontributionFee(e.target.value)}
          />
          <Button
            variant="contained"
            type="button"
            onClick={() => handleAction(VERIFIED)}
          >
            Submit
          </Button>
        </div>
      </Dialog>
      <>
        <div className={styles.profileDiv}>
          <div className={styles.imgDiv}>
            <img src={userData?.data?.profile?.profile_image} alt="profile" />
          </div>
          <span>BVN: {userData?.data?.profile?.bvn} </span>
        </div>

        <AwaitingListBody>
          <table className={styles.awaitingListTable}>
            <thead>
              <tr className={styles.tableRow}>
                <th>Surname</th>
                <th>First Name</th>
                <th>Other Name</th>
                <th>Sex</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{userData?.data?.profile?.last_name}</td>
                <td>{userData?.data?.profile?.first_name}</td>
                <td>{userData?.data?.profile?.other_name}</td>
                <td>{userData?.data?.profile?.gender}</td>
              </tr>
            </tbody>
          </table>
          <table className={styles.awaitingListTable}>
            <thead>
              <tr className={styles.tableRow}>
                <th>Date Of Birth</th>
                <th>State Of Origin</th>
                <th>Local Government Area</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{userData?.data?.profile?.date_of_birth}</td>
                <td>{userData?.data?.profile?.state_of_origin}</td>
                <td>{userData?.data?.profile?.local_governement_area}</td>
                <td>{userData?.data?.profile?.email}</td>
              </tr>
            </tbody>
          </table>
          <table className={styles.awaitingListTable}>
            <thead>
              <tr className={styles.tableRow}>
                <th>Alternate Email Address</th>
                <th>Mobile Number</th>
                <th>Alternate Mobile Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{userData?.data?.profile?.alt_email}</td>
                <td>{userData?.data?.profile?.phone}</td>
                <td>{userData?.data?.profile?.alt_phone}</td>
              </tr>
            </tbody>
          </table>
        </AwaitingListBody>

        <div className={styles.idDiv}>
          <div
            className={styles.h3Div}
            style={{
              // width: "220px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h4>ID: {userData?.data?.other_information?.means_of_id}</h4>
            <h5>
              <a
                href={`${userData?.data?.other_information?.means_of_id_file}`}
                target="_blank"
                without
                rel="noreferrer"
              >
                Link to ID Upload
              </a>
            </h5>
          </div>
        </div>
        <AwaitingListBody>
          <h3>Job Details</h3>
          <hr />
          <table className={styles.awaitingListTable}>
            <thead>
              <tr className={styles.tableRow}>
                <th>Employment Status</th>
                <th>Employer Name</th>
                <th>Office Address</th>
                <th>Monthly Income </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{userData?.data?.other_information?.employment_status}</td>
                <td>{userData?.data?.other_information?.employer_name}</td>
                <td>{userData?.data?.other_information?.office_address}</td>
                <td>{userData?.data?.other_information?.monthly_income}</td>
              </tr>
            </tbody>
          </table>
          <table className={styles.awaitingListTable}>
            <thead>
              <tr className={styles.tableRow}>
                <th>Office Contact Person's Name</th>
                <th>Office Contact Person's Phone NUmber</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {
                    userData?.data?.other_information
                      ?.office_contact_person_name
                  }
                </td>
                <td>
                  {
                    userData?.data?.other_information
                      ?.office_contact_person_phone_number
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </AwaitingListBody>

        <AwaitingListBody>
          <h3>Reference Details</h3>
          <hr />
          <table className={styles.awaitingListTable}>
            <thead>
              <tr className={styles.tableRow}>
                <th>Name of Reference</th>
                <th>Contact of Reference</th>
                <th>Name of Next of Kin</th>
                <th>Contact of next of kin </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{userData?.data?.other_information?.name_of_reference}</td>
                <td>
                  {userData?.data?.other_information?.contact_of_reference}
                </td>
                <td>
                  {userData?.data?.other_information?.name_of_next_of_kin}
                </td>
                <td>
                  {userData?.data?.other_information?.contact_of_next_kin}
                </td>
              </tr>
            </tbody>
          </table>
          <table className={styles.awaitingListTable}>
            <thead>
              <tr className={styles.tableRow}>
                <th>Next of kin Email</th>
                <th>Next of Kin Home Address</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {userData?.data?.other_information?.email_of_next_of_kin}
                </td>
                <td>
                  {userData?.data?.other_information?.next_of_kin_home_address}
                </td>
              </tr>
            </tbody>
          </table>
        </AwaitingListBody>
        {apartmentDetails && (
          <AwaitingListBody>
            <h3>Current Resident Address</h3>
            <hr />
            <table className={styles.awaitingListTable}>
              <thead>
                <tr className={styles.tableRow}>
                  <th>Current Home Address</th>
                  <th>Current Rent Value</th>
                  <th>Name Of Landlord </th>
                  <th>Contact Of Landlord </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {apartmentDetails?.house_number} {apartmentDetails?.street}{" "}
                    {apartmentDetails?.state} {apartmentDetails?.lga}{" "}
                    {apartmentDetails?.lcda}
                  </td>
                  <td>{apartmentDetails?.price}</td>
                  <td>
                    {ownerDetails?.profile?.first_name}{" "}
                    {ownerDetails?.profile?.last_name}
                  </td>
                  <td>{ownerDetails?.profile?.phone}</td>
                </tr>
              </tbody>
            </table>
            <table className={styles.awaitingListTable}>
              <thead>
                <tr className={styles.tableRow}>
                  <th>Name Of Manager</th>
                  <th>Contact Of Manager</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {managerDetails?.profile?.first_name}{" "}
                    {managerDetails?.profile?.last_name}
                  </td>
                  <td>{managerDetails?.profile?.phone}</td>
                </tr>
              </tbody>
            </table>
          </AwaitingListBody>
        )}
        {!applicationData?.payload[0]?.laid && (
          <AwaitingListBody>
            <h3>Rent Range</h3>
            <hr />
            <p>{applicationData?.payload[0]?.rent_range}</p>
          </AwaitingListBody>
        )}
        <div className={styles.idDiv}>
          <a
            href={`${userData?.data?.other_information?.account_statement_doc}`}
            style={{
              width: "220px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            target="_blank"
            without
            rel="noreferrer"
          >
            <div className={styles.h3Div}>
              <div className={styles.fileDiv}>
                <span className={styles.file}>
                  <AiFillFileText />
                </span>
                <span className={styles.statement}>Statement Of account</span>
              </div>
            </div>
          </a>
        </div>
        {userApplication?.monthly_rent_status_stage_1 !== "verified" ||
          userApplication?.monthly_rent_status_stage_1 !== "rejected" ? (
          <div className={styles.verifyBtn}>
            <Button
              variant="contained"
              type="button"
              style={{ background: "red" }}
              onClick={() => handleAction(REJECTED)}
            >
              Decline
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => handleSetModalVisibility()}
            >
              Review
            </Button>
            <Button
              variant="contained"
              type="button"
              style={{ background: "green" }}
              onClick={() => handleOpenModal()}
            >
              Verify
            </Button>
          </div>
        ) : null}
      </>
    </Container>
  );
};

export default VerifyStageOneView;
