import { Box, keyframes, styled, IconButton } from "@mui/material";
import React, { useState } from "react";
import SidebarLink from "./SidebarLink";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

export default function SidebarDropdown({ item }) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link);
  };

  return (
    <div>
      <Box
        display={"flex"}
        onClick={() => setIsOpen(!isOpen)}
        sx={{ cursor: "pointer" }}
      >
        <SidebarLink logo={item.logo} text={item.name} />
        <StyledIconButton open={isOpen}>
          {isOpen ? <ExpandMore /> : <ExpandLess />}
        </StyledIconButton>
      </Box>

      <StyledList open={isOpen}>
        {item.subLinks.map((link) => (
          <StyledListItem
            onClick={() => handleNavigate(link.link)}
            key={link.id}
          >
            {link.name}
          </StyledListItem>
        ))}
      </StyledList>
    </div>
  );
}

const rotateOpen = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

const rotateClose = keyframes`
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

const StyledIconButton = styled(Box)`
  transition: transform 0.3s;
  color: #ffff;
  animation: ease-in 0.5s forwards;
`;

const StyledList = styled("ul")`
  list-style: none;
  padding: 0 8px;
  margin-top: -15px;
  overflow: hidden;
  height: ${(props) => (props.open ? "auto" : 0)};
  transition: height 0.3s;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledListItem = styled("li")`
  animation: ${fadeIn} 0.5s ease-in;
  cursor: pointer;
  padding: 5px 10px;
  font-weight: bold;
  &:hover {
    background-color: #eee;
  }
`;
