import { styled } from '@mui/material'
import {AiOutlineLeft} from "react-icons/ai"
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Container = styled("div")`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 3px 0;
    gap: 7px;
    width: 100%;
    border-bottom: 1px solid rgba(5, 33, 55, 0.5);
    margin-bottom: 60px;
    font-size: 12px;
    cursor: pointer;
`

const BackComp = () => {
  const navigate = useNavigate()
  return (
      <Container onClick={()=>navigate(-1)}>
          <AiOutlineLeft />
          <p>BACK</p>
    </Container>
  )
}

export default BackComp