import { walletServiceApi } from "app/apis/walletService";

export const referralApiSlice = walletServiceApi.injectEndpoints({
  endpoints: (builder) => ({
    payoutUserCommission: builder.mutation({
      query: ({ lid, amount, reason }) => ({
        url: `/admin/izypay/commission/${lid}/withdraw`,
        method: "DELETE",
        body: { amount, reason },
      }),
    }),
  }),
});

export const { usePayoutUserCommissionMutation } = referralApiSlice;
