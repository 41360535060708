import { Grid, styled, Typography } from "@mui/material";
import { authService, izypromanService } from "apis";
import DetailsCardContainer from "components/cards/DetailsCardContainer";
import React, { useEffect } from "react";
import { useState } from "react";
import { toCurrency } from "utils/misc";
import { useAppContext } from "../../context/context";
import LatestUsersTable from "./components/LatestUsersTable";
import SubtitleAndSubtext from "./components/SubtitleAndSubtext";

const SectionTitle = styled(Typography)`
  text-transform: uppercase;
  color: rgba(5, 33, 55, 0.7);
  font-weight: bold;
  font-size: 1.1rem;
`;

const Dashboard = () => {
  const { setNavState } = useAppContext();
  const [latestUsers, setLatestUsers] = useState([]);
  const [summary, setSummary] = useState({
    users: 0,
    managers: 0,
    landlords: 0,
    tenants: 0,
    apartments: 0,
    monthlyRentUsers: 0,
    monthlyRentValue: 0,
    totalRentValue: 0,
  });
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      setLoadingUsers(true);
      authService()
        .get("/admin/users", { params: { page: 1 } })
        .then((res) => {
          const data = res.data.data;
          const total = res.data.meta.total;
          // const pageSize = res.data.meta.per_page;
          // const page = res.data.meta.current_page;
          setSummary((prev) => ({ ...prev, users: total }));
          setLatestUsers(data);
          setLoadingUsers(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, []);

  // get tenants count
  useEffect(() => {
    izypromanService()
      .get("/tenant/index/all")
      .then((res) => {
        const data = res.data.payload;
        setSummary((prev) => ({ ...prev, tenants: data.length }));
        let monthlyRentUsersCount = 0;

        data.forEach((tenant) => {
          if (
            tenant.monthly_rent_status_stage_1 === "verified" &&
            tenant.monthly_rent_status_stage_2 === "accepted"
          ) {
            monthlyRentUsersCount++;
          }
        });
        setSummary((prev) => ({
          ...prev,
          monthlyRentUsers: monthlyRentUsersCount,
        }));
        return;
      });
  }, []);

  // get apartment count
  useEffect(() => {
    izypromanService()
      .get("/apartment/admin")
      .then((res) => {
        const data = res.data.payload;
        setSummary((prev) => ({ ...prev, apartments: data.length }));

        const totalRentValue = data.reduce((totalRent, apartment) => {
          return totalRent + apartment.price;
        }, 0);
        setSummary((prev) => ({ ...prev, totalRentValue }));
      });
  }, []);

  // get landlord count
  useEffect(() => {
    izypromanService()
      .get("/property-owner/all")
      .then((res) =>
        setSummary((prev) => ({ ...prev, landlords: res.data.payload.length }))
      );
  }, []);

  // get agents count
  useEffect(() => {
    izypromanService()
      .get("/property-manager/all")
      .then((res) =>
        setSummary((prev) => ({ ...prev, managers: res.data.payload.length }))
      );
  }, []);

  useEffect(() => {
    setNavState("DASHBOARD");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {/* Top Section */}
      <Grid container spacing={3}>
        {/* Left Side */}
        <Grid item lg={8}>
          <section>
            <SectionTitle>Summary</SectionTitle>
            <div>
              <DetailsCardContainer>
                <Grid container rowSpacing={2}>
                  <Grid item xs={3}>
                    <SubtitleAndSubtext
                      subtitle={"Users"}
                      subtext={summary.users}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SubtitleAndSubtext
                      subtitle={"Tenants"}
                      subtext={summary.tenants}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SubtitleAndSubtext
                      subtitle={"Managers"}
                      subtext={summary.managers}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SubtitleAndSubtext
                      subtitle={"Landlords"}
                      subtext={summary.landlords}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SubtitleAndSubtext
                      subtitle={"Apartments"}
                      subtext={summary.apartments}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SubtitleAndSubtext
                      subtitle={"Total Rent Value"}
                      subtext={toCurrency(summary.totalRentValue)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SubtitleAndSubtext
                      subtitle={"Monthly Rent Users"}
                      subtext={summary.monthlyRentUsers}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SubtitleAndSubtext
                      subtitle={"Monthly Rent Value"}
                      subtext={toCurrency(summary.monthlyRentValue)}
                    />
                  </Grid>
                </Grid>
              </DetailsCardContainer>
            </div>
          </section>
        </Grid>

        {/* Right side */}
        <Grid item lg={4}>
          <SectionTitle>Pending Requests</SectionTitle>
        </Grid>
      </Grid>

      {/* Bottom Section */}
      <Grid container spacing={3}>
        {/* Left Side */}
        <Grid item lg={8}>
          <SectionTitle>Statistics</SectionTitle>
        </Grid>
        <Grid item lg={4}>
          <SectionTitle>New Users</SectionTitle>
          <LatestUsersTable latestUsers={latestUsers} loading={loadingUsers} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
