export const displayAlertBar = (dispatch, type, message) => {
  dispatch({
    type: 'DISPLAY_ALERT',
    payload: { open: true, type, duration: 6000, message },
  });
};

export const displaySuccessAlert = (dispatch, message, duration=6000) => {
  dispatch({
    type: 'DISPLAY_ALERT',
    payload: { open: true, type: 'success', duration, message },
  });
};

export const displayErrorAlert = (dispatch, message, duration=6000) => {
  dispatch({
    type: 'DISPLAY_ALERT',
    payload: { open: true, type: 'error', duration, message },
  });
};

export const displayPageLoader = (dispatch, open=true) => {
  dispatch({
    type: 'DISPLAY_PAGE_LOADER',
    payload: { open },
  })
};
