import TitleUnderline from "components/parts/TitleUnderline";
import React, { useState } from "react";
import ProfileSection from "./ProfileSection";
import { Button, CircularProgress } from "@mui/material";
import { useGetUserDetailsQuery } from "features/auth/authApiSlice";
import { useParams } from "react-router-dom";
import BackComp from "components/parts/BackComp";
import AddBillModal from "components/modals/AddBillModal";
import useFetch from "hooks/useFetch";
import BillsAccordion from "components/accordions/BillsAccordion";
import { izypromanService } from "apis";

const MonthlyRentTenantView = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { liveizy_id, apartment_id } = useParams();
  const { data: userData } = useGetUserDetailsQuery(liveizy_id);

  const endpoint = `/bill/get_apartment_bills/${apartment_id}`;
  const { data, loading, refetch } = useFetch(izypromanService(), endpoint);

  const handleOpen = () => setModalIsOpen(true);
  const handleClose = () => setModalIsOpen(false);

  if (loading) return <CircularProgress />;
  return (
    <div>
      <AddBillModal
        open={modalIsOpen}
        handleClose={handleClose}
        LID={userData?.data?.profile?.liveizy_id}
        AID={apartment_id}
        property={apartment_id}
        refetch={refetch}
      />
      <BackComp />
      <section>
        <TitleUnderline title={"Tenants Details"} />
        <ProfileSection tenant={userData?.data} />
      </section>

      <section>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <h2>IzyRent Monthly Bills</h2>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button style={{ width: 'max-content' }} onClick={handleOpen} variant="contained">Add Bill</Button>
          </div>
        </div>
        <hr />
        <BillsAccordion data={data?.data} refetch={refetch} />
      </section>
    </div>
  );
};

export default MonthlyRentTenantView;
