import BackComp from 'components/parts/BackComp'
import TitleUnderline from 'components/parts/TitleUnderline'
import React from 'react'
import ProfileSection from './ProfileSection'
import { useParams } from 'react-router-dom'
import { useGetUserDetailsQuery } from 'features/auth/authApiSlice'
import Renewals from '../../Renewals'

const LoanRenewalsView = () => {
    const { liveizy_id, apartment_id } = useParams();
    const { data: userData } = useGetUserDetailsQuery(liveizy_id);

    return (
        <>
            <BackComp />
            <section>
                <TitleUnderline title={"Tenants Details"} />
                <ProfileSection tenant={userData?.data} />
            </section>
            <Renewals apartmentID={apartment_id} liveizyID={liveizy_id} />
        </>
    )
}

export default LoanRenewalsView