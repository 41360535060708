import { RiDashboardLine } from "react-icons/ri";
import { HiOutlineUsers, HiOutlineUserAdd } from "react-icons/hi";
import { FaHome } from "react-icons/fa";
import { ROUTES } from "../../constants/routes";

export const sidebarLinks = [
  {
    key: 1,
    logo: <RiDashboardLine />,
    name: "dashboard",
    link: ROUTES.dashboard,
    permittedRoles: ["LiveizySuperAdmin"],
  },
  {
    key: 2,
    logo: <HiOutlineUsers />,
    name: "users",
    link: ROUTES.users,
    permittedRoles: ["LiveizySuperAdmin"],
  },
  {
    key: 3,
    logo: <FaHome />,
    name: "property >",
    link: ROUTES.property,
    permittedRoles: ["LiveizySuperAdmin"],
  },
  {
    key: 4,
    logo: <HiOutlineUserAdd />,
    name: "add Admin",
    link: ROUTES.addAdmin,
    permittedRoles: ["LiveizySuperAdmin"],
  },
];
