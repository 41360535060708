import { styled } from "@mui/material";
import React from "react";
import SubtitleAndSubtext from "./SubtitleAndSubtext";
// import defaultProfileImage from "../../mock data/mockDp.png";

const ProfilePx = styled("div")`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Details = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: left;
  gap: 1.5rem;
`;

const ProfileSection = ({ tenant }) => {
  return (
    <div style={{marginBottom: '3rem'}}>
      {/* Profile pic and BVN */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: '1rem'
        }}
      >
        <ProfilePx>
          <img src={tenant?.profile?.profile_image} alt="profile pic" />
        </ProfilePx>
        <p style={{ fontWeight: "bold" }}>LID: {tenant?.profile?.liveizy_id}</p>
        <p>BVN: {tenant?.profile?.bvn}</p>
      </div>
      {/* Details */}
      <Details>
        <SubtitleAndSubtext
          subtitle={"Full Name"}
          subtext={`${tenant?.profile?.first_name} ${tenant?.profile?.last_name}`}
        />
        <SubtitleAndSubtext
          subtitle={"Email"}
          subtext={tenant?.profile?.email}
        />

        <SubtitleAndSubtext
          subtitle={"Alternate Email"}
          subtext={tenant?.profile?.alt_email}
        />

        <SubtitleAndSubtext
          subtitle={"Mobile Number"}
          subtext={tenant?.profile?.phone}
        />
        <SubtitleAndSubtext
          subtitle={"Alternate Mobile Number"}
          subtext={tenant?.profile?.alt_phone}
        />

        <SubtitleAndSubtext
          subtitle={"Employment Status"}
          subtext={tenant?.other_information?.employment_status}
        />
        <SubtitleAndSubtext
          subtitle={"Office Address"}
          subtext={tenant?.other_information?.office_address}
        />

        <SubtitleAndSubtext
          subtitle={"Monthly Income"}
          subtext={tenant?.other_information?.monthly_income}
        />
      </Details>
    </div>
  );
};

export default ProfileSection;
