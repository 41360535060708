/* eslint-disable no-unused-vars */
import { Navigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";

export const checkLoggedIn = () => {
  const token = localStorage.getItem("Authorization");
  if (!token || typeof token !== "string") return false;
  // const payload = JSON.parse(window.atob(token.split(".")[1]));
  // if (payload.exp * 1000 < Date.now()) {
  //   localStorage.clear();
  //   return false;
  // }
  return true;
};

// export const checkLoggedIn = (location) => {
//   const token = localStorage.getItem("Authorization");
//   if (!token || typeof token !== "string")
//     return <Navigate to={ROUTES.login} state={{ from: location }} replace />;
//   const payload = JSON.parse(window.atob(token.split(".")[1]));
//   if (payload.exp * 1000 < Date.now()) {
//     localStorage.clear();
//     return <Navigate to={ROUTES.login} state={{ from: location }} replace />;
//   }
// };

export const logout = (history) => {
  localStorage.clear();
  history.push(ROUTES.login);
};

export const getUserProfile = () => {
  const user = localStorage.getItem("APP_USER_DETAIL");
  return JSON.parse(user);
};
