import { walletServiceApi } from "app/apis/walletService";

export const izyInvestApiSlice = walletServiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsersIzyInvestPlans: builder.query({
      query: (expires) => `admin/izyinvest/user/plans?expires=${expires}`,
    }),
  }),
});

export const { useGetUsersIzyInvestPlansQuery } = izyInvestApiSlice;
