import Alert from "@mui/material/Alert";
// import { useEffect } from "react";
import { useAppContext } from "../../context/context";

const AlertBar = () => {
  const { alertSeverity, alertText } = useAppContext();
  return <Alert severity={alertSeverity}>{alertText}</Alert>;
};

export default AlertBar;
