import { styled } from "@mui/material";
import React from "react";

const Container = styled("div")`
  text-overflow: ellipsis;
  display: grid;
  gap: 3px;
`;

const SubtitleAndSubtext = ({ subtitle, subtext }) => {
  return (
    <Container>
      <p style={{fontSize: '18px', fontWeight: 600, color: "#052137B2", marginBottom: '5px' }}>{subtitle}</p>
      <small style={{ fontSize: '16px', fontWeight: 500, color: "#052137", }}>
        {subtext}
      </small>
    </Container>
  );
};

export default SubtitleAndSubtext;
