import { configureStore } from "@reduxjs/toolkit";
import { authServiceApi } from "app/apis/authService";
import { propertyServiceApi } from "app/apis/propertyService";
import { walletServiceApi } from "app/apis/walletService";

export const store = configureStore({
  reducer: {
    [authServiceApi.reducerPath]: authServiceApi.reducer,
    [walletServiceApi.reducerPath]: walletServiceApi.reducer,
    [propertyServiceApi.reducerPath]: propertyServiceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authServiceApi.middleware,
      walletServiceApi.middleware,
      propertyServiceApi.middleware
    ),
});
