import {
  INCOMPLETE_FIELD_ALERT,
  LOGIN_USER_SUCCESS,
  SERVER_ERROR,
  LOGIN_USER_FAIL,
  LOGOUT,
} from "./actions";

const reducer = (state, action) => {
  if (action.type === INCOMPLETE_FIELD_ALERT) {
    return {
      ...state,
      alertSeverity: "error",
      alertText: "Please provide all details.",
      showAlert: true,
    };
  }

  if (action.type === LOGIN_USER_SUCCESS) {
    const { user, token } = action.payload;
    return {
      ...state,
      user,
      token,
    };
  }

  if (action.type === LOGIN_USER_FAIL) {
    const { msg } = action.payload;
    return {
      ...state,
      alertSeverity: "error",
      alertText: msg,
      showAlert: true,
    };
  }

  if (action.type === SERVER_ERROR) {
    return {
      ...state,
      alertSeverity: "error",
      alertText: "Server Error",
      showAlert: true,
    };
  }

  if (action.type === LOGOUT) {
    return {
      ...state,
      user: null,
      token: null,
    };
  }
};

export default reducer;
