import { Button } from "@mui/material";

function getFullName(params) {
  return `${params.row.first_name || ""} ${params.row.last_name || ""}`;
}

export const usersWithReferralsTableColumns = (
  setSelectedUser,
  setModalIsOpen
) => {
  return [
    {
      field: "fullName",
      headerName: <strong>Full Name</strong>,
      flex: 2,
      width: 280,
      valueGetter: getFullName,
    },
    {
      field: "liveizy_id",
      headerName: <strong>ID</strong>,
      flex: 1,
      width: 100,
    },
    {
      field: "tenants",
      headerName: <strong>Tenants</strong>,
      flex: 1,
      width: 100,
      valueGetter: (params) => params.row.tenant_referrals.length,
    },
    {
      field: "commission_balance",
      headerName: <strong>Balance</strong>,
      flex: 1,
      width: 100,
    },
    {
      field: "total_payouts",
      headerName: <strong>Payout</strong>,
      flex: 1,
      width: 100,
    },

    {
      field: "view",
      headerName: "",
      flex: 1,
      width: 100,
      renderCell: (params) => {
        return (
          <Button
            color="primary"
            variant="outlined"
            sx={{ fontSize: "10px" }}
            onClick={() => {
              setSelectedUser(params.row);
              setModalIsOpen(true);
              return;
            }}
          >
            Pay
          </Button>
        );
      },
    },
  ];
};
