import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { StateProvider } from "./global-store/popupContext";
import Alert from "./components/popups/Alert";
import PageLoader from "./components/popups/PageLoader";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import { AppProvider } from "./context/context";
import { Provider } from "react-redux";
import { store } from "app/state/store";

ReactDOM.render(
  <Provider store={store}>
    <StateProvider>
      <AppProvider>
        <App />
        <Alert />
        <PageLoader />
      </AppProvider>
    </StateProvider>
  </Provider>,

  document.querySelector("#root")
);
