import { styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navbar from "../../layout/navbar/Navbar";
import Sidebar from "../../layout/sidebar/Sidebar";

const Container = styled("div")`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;

const OutletContainer = styled("div")`
  width: 100%;
  padding: 20px 30px;
`;

const SharedLayout = () => {
  return (
    <Container>
      <Sidebar />
      <div style={{ padding: "20px", width: "100%", maxHeight: '100vh', overflowY: 'auto' }}>
        <Navbar />
        <OutletContainer className="position-center">
          <Outlet />
        </OutletContainer>
      </div>
    </Container>
  );
};

export default SharedLayout;
