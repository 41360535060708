import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function DataTable({ rows, columns, pageSize, ...otherProps }) {
  return (
    <div
      style={{
        // height: 800,
        width: "100%",
        boxShadow: "0px 4px 31px -4px rgba(0,0,0,0.25)",
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        {...otherProps}
      />
    </div>
  );
}
