import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { checkLoggedIn } from "../utils/auth";

const ProtectedRoute = () => {
  const location = useLocation();
  const isLoggedIn = checkLoggedIn();
  if (isLoggedIn) {
    return <Outlet />;
  }
  return <Navigate to={ROUTES.login} state={{ from: location }} replace />;
};

export default ProtectedRoute;
