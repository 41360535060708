import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';

const useFetch = (api, url, options = {}) => {
    const { loadingMessage, errorMessage, refetchInterval } = options;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(
        async (signal) => {
            setLoading(true);
            setError(null);
            setData(null);

            try {
                const response = await api.get(url, { signal });
                const result = response.data.content || response.content || response.data;
                setData(result);
            } catch (err) {
                if (!axios.isCancel(err)) {
                    setError(err.message || errorMessage || 'An error occurred.');
                }
            } finally {
                setLoading(false);
            }
        },
        [url, errorMessage]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetchData(controller.signal);

        if (refetchInterval) {
            const interval = setInterval(() => fetchData(controller.signal), refetchInterval);
            return () => clearInterval(interval);
        }

        return () => {
            controller.abort();
        };
    }, [url, fetchData, refetchInterval]);

    const refetch = () => {
        fetchData();
    };

    return { data, loading, error, refetch, loadingMessage, errorMessage };
};

export default useFetch;
