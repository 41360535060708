import { styled } from '@mui/material'
import SubtitleAndSubtext from '../parts/SubtitleAndSubtext'
import DetailsCardContainer from './DetailsCardContainer'

const Details = styled("div")`
    display: flex;
    justify-content: flex-start;
`

const Column = styled("div")`
    margin-right: 50px;
`

const EmploymentDetails = ({ employmentStatus, officeContactPersonName,
  employerName,
  officeContactPersonNumber,
  officeAddress,
  monthlyIncome
}) => {
  return (
    <DetailsCardContainer>
      <Details>
        <Column>
          <SubtitleAndSubtext subtitle={"Employment Status"} subtext={employmentStatus}/>
          <SubtitleAndSubtext subtitle={"Office Contact Person Name"} subtext={officeContactPersonName}/>
        </Column>
        <Column>
          <SubtitleAndSubtext subtitle={"Employer Name"} subtext={employerName}/>
          <SubtitleAndSubtext subtitle={"Office Contact Person Number"} subtext={officeContactPersonNumber}/>
        </Column>
        <Column>
          <SubtitleAndSubtext subtitle={"Office Address"} subtext={officeAddress}/>
        </Column>
        <Column>
          <SubtitleAndSubtext subtitle={"Monthly Income"} subtext={monthlyIncome}/>
        </Column>
      </Details>
    </DetailsCardContainer>
  )
}

export default EmploymentDetails