import React from "react";
import { FiPower } from "react-icons/fi";
import { RiMenu4Fill } from "react-icons/ri";
import Badge from "@mui/material/Badge";
import { FaRegBell } from "react-icons/fa";
import { styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "context/context";
import { LOGOUT } from "context/actions";
import { ROUTES } from "constants/routes";

const Container = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  font-size: 20px;
`;

const ActiveBar = styled("div")`
  gap: 15px;
`;

const ActionButtons = styled("div")`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const Navbar = () => {
  const location = useLocation();
  const currentRoute = location.pathname.replace(/^\//, '');
  const { navState, dispatch } = useAppContext();
  const navigate = useNavigate();

  const logout = () => {
    dispatch({ type: LOGOUT });
    localStorage.clear();
    navigate(ROUTES.login);
  };
  return (
    <Container>
      <ActiveBar style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
      }}>
        <RiMenu4Fill />
        <p className="uppercase">{currentRoute.replace(/\//g, " - ")}</p>
      </ActiveBar>
      <ActionButtons className="position-center">
        <Badge color="primary">
          <FaRegBell size={23} />
        </Badge>
        <FiPower size={23} onClick={logout} style={{ cursor: "pointer" }} />
      </ActionButtons>
    </Container>
  );
};

export default Navbar;
