/* eslint-disable no-unused-vars */
import React, { useContext, useReducer, useState } from "react";
import { authService } from "../apis";

import { useLocation, useNavigate } from "react-router-dom";
import { INCOMPLETE_FIELD_ALERT, LOGIN_USER_SUCCESS } from "./actions";
import reducer from "./reducer";

const AppContext = React.createContext();

const user = localStorage.getItem("APP_USER_DETAIL");
const token = localStorage.getItem("Authorization");

const initialState = {
  user: user ? JSON.parse(user) : null,
  token: token ? token : null,
  isLoading: false,
  alertSeverity: "",
  alertText: "",
  showAlert: false,
};

export const AppProvider = ({ children }) => {
  const [navState, setNavState] = useState("");
  const [userTablePage, setUserTablePage] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const displayAlert = () => {
    dispatch({ type: INCOMPLETE_FIELD_ALERT });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        displayAlert,
        dispatch,
        navState,
        setNavState,
        userTablePage,
        setUserTablePage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
