import { styled } from "@mui/material";
import { useEffect, useState } from "react";
import { izypromanService } from "../../apis";
import { toCurrency } from "../../utils/misc";
import SubtitleAndSubtext from "../parts/SubtitleAndSubtext";
import DetailsCardContainer from "./DetailsCardContainer";

const Details = styled("div")`
  display: flex;
  justify-content: flex-start;
`;

const Column = styled("div")`
  margin-right: 50px;
`;

const TenancyDetails = ({ lid }) => {
  const [tenancyDetails, setTenancyDetails] = useState({
    homeAddress: null,
    rentValue: null,
    property_manager: null,
    property_manager_phone: null,
    property_owner: null,
    property_owner_phone: null,
    apartmentId: null,
    propertyId: null,
  });
  useEffect(() => {
    const getTenancyDetails = () => {
      if (lid) {
        izypromanService()
          .get(
            `/tenant/lid/${lid}?status=resident&populate1=apartment_id&populate2=property`
          )
          .then((res) => {
            if (res.data.payload.length > 0) {
              const data = res.data.payload[0];
              const apartment = data.apartment_id;
              const homeAddress = `${apartment?.house_number}, ${apartment?.street}, ${apartment?.other_address}, ${apartment?.lga}, ${apartment?.state}, ${apartment?.country}`;
              const rentValue = toCurrency(apartment?.price);
              const apartmentId = apartment._id;
              const propertyId = data.property._id;
              setTenancyDetails((prev) => ({
                ...prev,
                homeAddress,
                rentValue,
                apartmentId,
                propertyId,
              }));

              if (data.property?.owner) {
                izypromanService()
                  .get(`/property-owner/${data.property?.owner}`)
                  .then((res) => {
                    const property_owner =
                      res.data.payload.first_name +
                      " " +
                      res.data.payload.last_name;
                    const property_owner_phone = res.data.payload.phone1;
                    setTenancyDetails((prev) => ({
                      ...prev,
                      property_owner,
                      property_owner_phone,
                    }));
                  });
              }
              if (data.property?.property_manager) {
                izypromanService()
                  .get(`/property-manager/${data.property?.property_manager}`)
                  .then((res) => {
                    const property_manager =
                      res.data.payload.first_name +
                      " " +
                      res.data.payload.last_name;
                    const property_manager_phone = res.data.payload.phone1;
                    setTenancyDetails((prev) => ({
                      ...prev,
                      property_manager,
                      property_manager_phone,
                    }));
                  });
              }
            }
          });
      }
    };
    getTenancyDetails();
  }, [lid]);
  return (
    <DetailsCardContainer>
      <Details>
        <Column>
          <SubtitleAndSubtext
            subtitle={"Current Home Address"}
            subtext={tenancyDetails?.homeAddress}
          />
          <SubtitleAndSubtext
            subtitle={"Name of Manager"}
            subtext={tenancyDetails?.property_manager}
          />
        </Column>
        <Column>
          <SubtitleAndSubtext
            subtitle={"Current Rent Value"}
            subtext={tenancyDetails?.rentValue}
          />
          <SubtitleAndSubtext
            subtitle={"Contact of Manager"}
            subtext={tenancyDetails?.property_manager_phone}
          />
        </Column>
        <Column>
          <SubtitleAndSubtext
            subtitle={"Name of Landlord"}
            subtext={tenancyDetails?.property_owner}
          />
          <SubtitleAndSubtext
            subtitle={"Property ID"}
            subtext={tenancyDetails?.propertyId}
          />
        </Column>
        <Column>
          <SubtitleAndSubtext
            subtitle={"Contact of Landlord"}
            subtext={tenancyDetails?.property_owner_phone}
          />
          <SubtitleAndSubtext
            subtitle={"Apartment"}
            subtext={tenancyDetails?.apartmentId}
          />
        </Column>
      </Details>
    </DetailsCardContainer>
  );
};

export default TenancyDetails;
