import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_WALLET_SERVICE,
  prepareHeaders: (headers, { getState }) => {
    const authToken = localStorage.getItem("Authorization");
    // const token = getState().auth.token;
    if (authToken) {
      headers.set("authorization", `Bearer ${authToken}`);
    }
    return headers;
  },
});

const baseQueryWithAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    localStorage.clear();
    window.location.replace("/login");
  }

  return result;
};

export const walletServiceApi = createApi({
  reducerPath: "walletServiceApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({}),
  tagTypes: [],
});
