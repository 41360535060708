import React, { useState } from 'react'
import { formatPrice, renderDate2, } from 'utils/misc';
import { Button, CircularProgress, styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AiOutlineDown } from 'react-icons/ai';

import { izypromanService } from 'apis';
import useFetch from 'hooks/useFetch';
import EditRenewal from 'components/modals/EditRenewal';

const Renewals = ({ apartmentID, liveizyID }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const { data, loading } = useFetch(izypromanService(), `apartment-bills/${apartmentID}`, {
        errorMessage: "Error getting data",
    });

    const { data: currentRenewal, } = useFetch(izypromanService(), `/current-renewal/${liveizyID}`, {
        errorMessage: "Error getting data",
    });

    const handleOpen = () => setModalIsOpen(true);
    const handleClose = () => setModalIsOpen(false);

    const renderRenewals = () => {
        if (data?.data) {
            return Object.keys(data?.data).map(key => (
                <AccordionDiv key={key}>
                    <AccSummary
                        expandIcon={<AiOutlineDown />}
                        aria-controls={`${key}-content`}
                        id={`${key}-header`}
                    >
                        <PropertySummary>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                                <h4>
                                    <strong>{key}</strong>
                                </h4>
                            </div>
                            <div>
                                <Button
                                    sx={{
                                        width: "auto",
                                        height: "40px",
                                        fontSize: "11px",
                                        color: "white",
                                        background: "#10609F",
                                    }}
                                >
                                    View All
                                </Button>
                            </div>
                        </PropertySummary>
                    </AccSummary>
                    <AccordionDetails>
                        {data?.data[key]?.map((item, index) => (
                            <ApartmentSummary key={index}>
                                <div>
                                    <h4>Invoice ref#</h4>
                                    <p>{item?.payment_ref[0]?.invoice_id || 'Not Generated'}</p>
                                </div>
                                <div>
                                    <h4>Amount</h4>
                                    <p>{formatPrice(item?.amount)}</p>
                                </div>
                                <div>
                                    <h4>Due Date</h4>
                                    <p>{renderDate2(item?.payment_date)}</p>
                                </div>
                                <div>
                                    <h4>Paid Date</h4>
                                    <p>{renderDate2(item?.paid_date) || '-'}</p>
                                </div>
                                <div>
                                    <h4>Overdue Date</h4>
                                    <p>{renderDate2(item?.overdue_date) || '-'}</p>
                                </div>
                            </ApartmentSummary>
                        ))}
                    </AccordionDetails>
                </AccordionDiv>
            ));
        }
        return null;
    }

    if (loading) return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3rem' }}><CircularProgress /></div>

    return (
        <>
            <EditRenewal
                open={modalIsOpen}
                handleClose={handleClose}
                id={currentRenewal?.data?._id}
            />
            <section style={{ marginTop: ' 5rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1rem 0' }}>
                    <h3>Previous Monthly Rent Records</h3>
                </div>
                <hr />
                {renderRenewals()}

                {currentRenewal?.data && (
                    <div style={{ marginTop: ' 5rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1rem 0' }}>
                            <h3>Current Loan Rent Renewal</h3>
                        </div>
                        <hr />
                        <div style={{
                            padding: '2.5rem 4rem', boxShadow: '1px 1px 1rem #00000040', display: 'flex', justifyContent: 'space-between',
                            alignItems: 'center', marginTop: '2rem', borderRadius: '1rem'
                        }}>
                            <div>
                                <h4 style={{ marginBottom: '10px' }}>Is new apartment</h4>
                                <p>{currentRenewal?.data?.is_new_apartment ? 'Yes' : 'No'}</p>
                            </div>
                            <div>
                                <h4 style={{ marginBottom: '10px' }}>Rent Range</h4>
                                <p>{currentRenewal?.data?.apartment_rent_range}</p>
                            </div>
                            <div>
                                <h4 style={{ marginBottom: '10px' }}>Current Tenure</h4>
                                <p>{currentRenewal?.data?.currentTenure}</p>
                            </div>
                            <div>
                                <h4 style={{ marginBottom: '10px' }}>Current rent Value</h4>
                                <p>{formatPrice(currentRenewal?.data?.current_rent_value)}</p>
                            </div>
                            <div>
                                <h4 style={{ marginBottom: '10px' }}>Date created</h4>
                                <p>{renderDate2(currentRenewal?.data?.createdAt)}</p>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '2rem'
                        }}>
                            <Button sx={{
                                width: "7rem",
                                height: "40px",
                                fontSize: "1rem",
                                color: "white",
                                background: "#10609F",
                            }}
                                onClick={handleOpen}
                                color="primary"
                            >
                                Review
                            </Button>
                        </div>
                    </div>
                )}
            </section>
        </>
    )
}

export default Renewals




const AccordionDiv = styled(Accordion)`
  margin: 20px auto;
  width: 100%;
  padding: 0px;
  height: auto;
  font-size: 14px;
`;

const AccSummary = styled(AccordionSummary)`
  width: 100%;
  padding: 10px;
  height: 70px;
  margin: auto 0;
  background: white;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const PropertySummary = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
`;

const ApartmentSummary = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 20px auto;
`;
