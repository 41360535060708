import { authServiceApi } from "app/apis/authService";

export const authApiSlice = authServiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => "/admin/users",
    }),
    getUserDetails: builder.query({
      query: (liveizyId) => `/user/get/details/${liveizyId}`,
    }),
    getUsersWithReferrals: builder.query({
      query: (page = 1) => `/admin/users/referral-summary?page=${page}`,
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUsersWithReferralsQuery,
  useGetUserDetailsQuery,
} = authApiSlice;
