import { Button, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { useAppContext } from "context/context";
import ControlledCheckbox from "components/parts/ControlledCheckBox";
import DataTable from "components/parts/DataTable";
import TextandLogoField from "components/parts/TextandLogoField";
import {
  useGetAllUsersQuery,
  useGetUsersWithReferralsQuery,
} from "features/auth/authApiSlice";
import { usersWithReferralsTableColumns } from "./util";
import ReferralPayoutModal from "./components/modals/ReferralPayoutModal";

export default function Referral() {
  const { setNavState } = useAppContext();
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    page: 1,
    pageSize: 15,
  });
  const { data: usersdata } = useGetAllUsersQuery();
  const { data, isSuccess, isLoading } = useGetUsersWithReferralsQuery(
    tableData.page
  );
  const [selectedUser, setSelectedUser] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    setNavState("Referral");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      const users = data.data;
      const total = data.meta.total;
      const pageSize = data.meta.per_page;
      const page = data.meta.current_page;
      setTableData((prev) => ({
        ...prev,
        data: users,
        total,
        page,
        pageSize,
      }));
    }
  }, [data]);

  return (
    <Container>
      <ReferralPayoutModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        selectedUser={selectedUser}
      />
      <TopSection>
        <SearchBar>
          <TextandLogoField
            logo={<HiOutlinePencilAlt />}
            placeholder="Search users by name or ID"
            fieldType="text"
          />
          <Button
            variant="contained"
            startIcon={<BiSearch />}
            sx={{ width: "200px", height: "100%", background: "#10609F" }}
          >
            Search
          </Button>
        </SearchBar>
        <SortSection>
          <p style={{ marginTop: "0px" }}>
            <strong>Sort user list by:</strong>
          </p>
          <div className="position-center" style={{ marginTop: "-15px" }}>
            <ControlledCheckbox
              text={"All"}
              defaultChecked={true}
              size="small"
            />
            <ControlledCheckbox
              text={"Tenants"}
              defaultChecked={false}
              size="small"
            />
            <ControlledCheckbox
              text={"Property Managers"}
              defaultChecked={false}
              size="small"
            />
            <ControlledCheckbox
              text={"Landlords"}
              defaultChecked={false}
              size="small"
            />
          </div>
        </SortSection>
      </TopSection>
      {/* Table section */}
      <div>
        <DataTable
          autoHeight
          rows={tableData.data}
          columns={usersWithReferralsTableColumns(
            setSelectedUser,
            setModalIsOpen
          )}
          pageSize={tableData.pageSize}
          rowsPerPageOptions={[15]}
          rowCount={tableData.total}
          loading={isLoading}
          pagination
          page={tableData.page - 1}
          paginationMode="server"
          onPageChange={(newPage) =>
            setTableData((prevState) => ({ ...prevState, page: newPage + 1 }))
          }
          onPageSizeChange={(newPageSize) =>
            setTableData((prevState) => ({
              ...prevState,
              pageSize: newPageSize,
            }))
          }
        />
      </div>
    </Container>
  );
}

const Container = styled("div")`
  width: 100%;
`;
const TopSection = styled("section")`
  display: flex;
  align-self: center;
  justify-content: flex-start;
  gap: 50px;
  margin-bottom: 40px;
  width: 100%;
`;

const SearchBar = styled("div")`
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 4px solid rgba(16, 96, 159, 0.4);
  border-radius: 4px;
  height: 60px;
  width: 450px;
`;

const SortSection = styled("div")`
  display: flex;
  flex-direction: column;
`;
