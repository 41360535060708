import { Box, Container, styled } from "@mui/material";
import apartmentPicture from "../../../assets/images/propertyimage.png";
import React from "react";
import BackComp from "components/parts/BackComp";
import SubtitleAndSubtext from "components/parts/SubtitleAndSubtext";
import TitleUnderline from "components/parts/TitleUnderline";

const TopCard = styled(Box)`
  width: auto;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffff;
  border-radius: 12px;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.45);
  padding: 20px 30px;
`;

const ApartmentImage = styled(Box)`
  width: 220px;
  height: 170px;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Details = styled("div")`
  display: flex;
  justify-content: flex-start;
`;

const Column = styled("div")`
  margin-right: 20px;
  margin-top: -20px;
`;

const ApartmentView = () => {
  return (
    <Container>
      <BackComp />
      <TopCard>
        <ApartmentImage>
          <img src={apartmentPicture} alt="apartment slides" />
        </ApartmentImage>
        <Details>
          <Column>
            <SubtitleAndSubtext
              subtitle={"Property Name"}
              subtext={"Victory Villa"}
            />
            <SubtitleAndSubtext subtitle={"Property Type"} subtext={"duplex"} />
            <SubtitleAndSubtext
              subtitle={"Settlement Type"}
              subtext={"Compound"}
            />
          </Column>
          <Column>
            <SubtitleAndSubtext
              subtitle={"Settlement Numbering System"}
              subtext={"default"}
            />
            <SubtitleAndSubtext subtitle={"House Number"} subtext={"3"} />
            <SubtitleAndSubtext
              subtitle={"Street"}
              subtext={"Mohammed street"}
            />
          </Column>
        </Details>
      </TopCard>
      {/* Apartment Details */}
      <Box marginY="30px">
        <TitleUnderline title={"Apartment Details"} />
        <Details>
          <Column>
            <SubtitleAndSubtext
              subtitle={"Property Name"}
              subtext={"Victory Villa"}
            />
            <SubtitleAndSubtext subtitle={"Property Type"} subtext={"duplex"} />
            <SubtitleAndSubtext
              subtitle={"Settlement Type"}
              subtext={"Compound"}
            />
          </Column>
          <Column>
            <SubtitleAndSubtext
              subtitle={"Settlement Numbering System"}
              subtext={"default"}
            />
            <SubtitleAndSubtext subtitle={"House Number"} subtext={"3"} />
            <SubtitleAndSubtext
              subtitle={"Street"}
              subtext={"Mohammed street"}
            />
          </Column>
          <Column>
            <SubtitleAndSubtext
              subtitle={"Settlement Numbering System"}
              subtext={"default"}
            />
            <SubtitleAndSubtext subtitle={"House Number"} subtext={"3"} />
            <SubtitleAndSubtext
              subtitle={"Street"}
              subtext={"Mohammed street"}
            />
          </Column>
        </Details>
      </Box>
      <Box marginY="30px">
        <TitleUnderline title={"Apartment Amenities"} />
      </Box>
      <Box marginY="30px">
        <TitleUnderline title={"Apartment Charges"} />
      </Box>
      <Box marginY="30px">
        <TitleUnderline title={"Inspection Schedules"} />
      </Box>
      <Box marginY="30px">
        <TitleUnderline title={"Tenant"} />
      </Box>
    </Container>
  );
};

export default ApartmentView;
