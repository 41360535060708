import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AiOutlineDown } from "react-icons/ai";
import { Backdrop, Button, Fade, Modal, styled } from "@mui/material";
import { formatPrice, renderDate2 } from "utils/misc";
import UpdateBillsModal from "components/modals/UpdateBilllsModal";
import { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import usePost from "hooks/usePost";


const AccordionDiv = styled(Accordion)`
  margin: 20px auto;
  width: 100%;
  padding: 0px;
  height: auto;
  font-size: 14px;
`;

const AccSummary = styled(AccordionSummary)`
  width: 100%;
  padding: 10px;
  height: 70px;
  margin: auto 0;
  background: white;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const PropertySummary = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
`;

const ApartmentSummary = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 20px auto;
`;

export default function BillsAccordion({ data, refetch }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const [id, setId] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const today = new Date();

    const filteredData = data?.filter(el => el.is_loan_rent)

    const paidStatus = filteredData?.filter(el => el.status === 'paid');
    const unPaidStatus = filteredData?.filter(el => el.status === 'unpaid');
    const overDueStatus = filteredData?.filter(el => {
        const paymentDate = new Date(el.payment_date);
        const overdueDate = new Date(paymentDate);
        overdueDate.setDate(paymentDate.getDate() + 7);

        return el.status === 'unpaid' && today > overdueDate;
    });

    const handleOpen = () => setModalIsOpen(true);
    const handleClose = () => setModalIsOpen(false);


    const { mutate } = usePost(`/bill/delete_bill/admin/${id}`, 'delete', {
        onSuccess: () => alert('Bill Deleted'),
        onError: (error) => alert(error),
    });

    const handleDelete = () => {
        setConfirmDelete(true);
    };

    const handleConfirmDelete = () => {
        mutate()
        alert("Item deleted successfully.");
        setConfirmDelete(false);
    };

    const handleCancelDelete = () => {
        setConfirmDelete(false);
    };

    return (
        <>
            <UpdateBillsModal open={modalIsOpen}
                handleClose={handleClose}
                data={selected}
                refetch={refetch}
            />
            <AccordionDiv>
                <AccSummary
                    expandIcon={<AiOutlineDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <PropertySummary>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                            <h4>
                                <strong>Almost Due/Due Monthly </strong>
                            </h4>
                            <p style={{ color: 'red' }}>({unPaidStatus?.length})</p>
                        </div>
                        <div>
                            <Button
                                sx={{
                                    width: "auto",
                                    height: "40px",
                                    fontSize: "11px",
                                    color: "white",
                                    background: "#10609F",
                                }}
                            >
                                View All
                            </Button>
                        </div>
                    </PropertySummary>
                </AccSummary>
                <Details
                    array={unPaidStatus}
                    setId={setId}
                    handleOpen={handleOpen}
                    setSelected={setSelected}
                    handleDelete={handleDelete}
                    handleCancelDelete={handleCancelDelete}
                    handleConfirmDelete={handleCancelDelete}
                />
            </AccordionDiv>
            <AccordionDiv>
                <AccSummary
                    expandIcon={<AiOutlineDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <PropertySummary>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                            <h4>
                                <strong>Paid</strong>
                            </h4>
                            <p style={{ color: 'red' }}>({paidStatus?.length})</p>
                        </div>
                        <div>
                            <Button
                                sx={{
                                    width: "auto",
                                    height: "40px",
                                    fontSize: "11px",
                                    color: "white",
                                    background: "#10609F",
                                }}
                            >
                                View All
                            </Button>
                        </div>
                    </PropertySummary>
                </AccSummary>
                <AccordionDetails>
                    {paidStatus?.map(el => (
                        <ApartmentSummary key={el?._id}>
                            <div>
                                <h4>Invoice ref#</h4>
                                <p>{el?.payment_ref[0]?.invoice_id || 'Not Generated'}</p>
                            </div>
                            <div>
                                <h4>Amount</h4>
                                <p>{formatPrice(el?.amount)}</p>
                            </div>
                            <div>
                                <h4>Due Date</h4>
                                <p>{renderDate2(el?.payment_date)}</p>
                            </div>
                            <div>
                                <h4>Paid Date</h4>
                                <p>{renderDate2(el?.paid_date) || '-'}</p>
                            </div>
                            <div>
                                <h4>Paid Amount</h4>
                                <p>{formatPrice(el?.amount_paid)}</p>
                            </div>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <FaEdit
                                    size={23}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        handleOpen()
                                        setSelected(el);
                                    }}
                                />
                                <MdDeleteOutline
                                    size={25}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setId(el?._id);
                                        handleDelete();
                                    }}
                                />
                            </div>


                            {/* Delete Confirmation Modal */}
                            <Modal
                                open={confirmDelete}
                                onClose={handleCancelDelete}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={confirmDelete}>
                                    <div className="modal-parent">
                                        <div className="modal-confirm">
                                            <h2>Confirm Deletion</h2>
                                            <p style={{ marginTop: '7px' }}>Are you sure you want to delete this item?</p>
                                            <div className="modal-buttons" style={{ marginTop: '7px' }}>
                                                <Button onClick={handleCancelDelete} color="primary">
                                                    Cancel
                                                </Button>
                                                <Button onClick={handleConfirmDelete} color="secondary">
                                                    Delete
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                </Fade>
                            </Modal>
                        </ApartmentSummary>
                    ))}
                </AccordionDetails>
            </AccordionDiv>
            <AccordionDiv>
                <AccSummary
                    expandIcon={<AiOutlineDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <PropertySummary>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                            <h4>
                                <strong>Over Due</strong>
                            </h4>
                            <p style={{ color: 'red' }}>({overDueStatus?.length})</p>
                        </div>
                        <div>
                            <Button
                                sx={{
                                    width: "auto",
                                    height: "40px",
                                    fontSize: "11px",
                                    color: "white",
                                    background: "#10609F",
                                }}
                            >
                                View All
                            </Button>
                        </div>
                    </PropertySummary>
                </AccSummary>
                <AccordionDetails>
                    {overDueStatus?.map(el => (
                        <ApartmentSummary key={el?._id}>
                            <div>
                                <h4>Bill #</h4>
                                <p>{el?._id || '-'}</p>
                            </div>
                            <div>
                                <h4>Amount</h4>
                                <p>{formatPrice(el?.amount_due)}</p>
                            </div>
                            <div>
                                <h4>Due Date</h4>
                                <p>{renderDate2(el?.payment_date)}</p>
                            </div>
                            <div>
                                <h4># of Days</h4>
                                <p>{renderDate2(el?.createdAt)}</p>
                            </div>
                        </ApartmentSummary>
                    ))}
                </AccordionDetails>
            </AccordionDiv>
        </>
    );
}

const Details = ({ array,
    handleOpen,
    setSelected,
    setId,
    confirmDelete,
    handleCancelDelete,
    handleConfirmDelete,
    handleDelete
}) => {


    return (
        <AccordionDetails>
            {array?.map(el => (
                <ApartmentSummary key={el?._id}>
                    <div>
                        <h4>Date Activated</h4>
                        <p>{renderDate2(el?.created_date)}</p>
                    </div>
                    <div>
                        <h4>Amount Due</h4>
                        <p>{formatPrice(el?.amount_due)}</p>
                    </div>
                    <div>
                        <h4>Security Deposit</h4>
                        <p>{formatPrice(el?.amount_paid)}</p>
                    </div>
                    <div>
                        <h4>Monthly Payment</h4>
                        <p>{el?.is_disburse ? '₦0.00' : formatPrice(el?.amount)}</p>
                    </div>
                    <div>
                        <h4>Subscription</h4>
                        <p>{formatPrice(el?.subscription_fee)}</p>
                    </div>
                    <div>
                        <h4>Payment Date</h4>
                        <p>{renderDate2(el?.payment_date)}</p>
                    </div>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <FaEdit
                            size={23}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                handleOpen()
                                setSelected(el);
                            }}
                        />
                        <MdDeleteOutline
                            size={25}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setId(el?._id);
                                handleDelete();
                            }}
                        />
                    </div>
                </ApartmentSummary>
            ))}

            {/* Delete Confirmation Modal */}
            <Modal
                open={confirmDelete}
                onClose={handleCancelDelete}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={confirmDelete}>
                    <div className="modal-parent">
                        <div className="modal-confirm">
                            <h2>Confirm Deletion</h2>
                            <p style={{ marginTop: '7px' }}>Are you sure you want to delete this item?</p>
                            <div className="modal-buttons" style={{ marginTop: '7px' }}>
                                <Button onClick={handleCancelDelete} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleConfirmDelete} color="secondary">
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>

                </Fade>
            </Modal>
        </AccordionDetails>
    );
};
