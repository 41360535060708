import { styled } from "@mui/material";
import React from "react";
import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export default function PayoutDateRangePicker({ dateRange, setDateRange }) {
  const [startDate, endDate] = dateRange;
  return (
    <Wrapper>
      <CalendarMonthIcon fontSize="small" />
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        isClearable={true}
        placeholderText="Select payout date range"
      />
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  max-width: 260px;
  padding: 10px;
`;
