import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, CircularProgress, Typography } from "@mui/material";
import { toCurrency } from "utils/misc";
import CustomInputAndLogoField from "../CustomInputAndLogoField";
import { usePayoutUserCommissionMutation } from "../../referralApiSlice";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function ReferralPayoutModal({
  setModalIsOpen,
  modalIsOpen,
  selectedUser,
}) {
  const [payoutAmount, setPayoutAmount] = React.useState(0);
  const [reason, setReason] = React.useState("commission_payout");
  const [payoutCommision, { isLoading }] = usePayoutUserCommissionMutation();

  const handleCloseModal = () => {
    setPayoutAmount(0);
    setModalIsOpen(false);
  };

  const handlePayout = async () => {
    if (payoutAmount < 1) return;

    try {
      const payload = {
        lid: selectedUser.liveizy_id,
        amount: payoutAmount,
        reason,
      };
      const response = await payoutCommision(payload).unwrap();
      alert("Success");
      return handleCloseModal();
    } catch (error) {
      console.log(error?.response);
      console.error("rejected", error);
      alert(error);
    }
  };
  return (
    <div>
      <Dialog
        open={modalIsOpen}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={2}
        >
          <DialogTitle id="alert-dialog-title">{"Referral Payout"}</DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant="h5" component="h2">
              {selectedUser?.first_name} {selectedUser?.first_name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Email: {selectedUser?.email}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Tenants:{" "}
              {selectedUser.tenant_referrals &&
                `${selectedUser?.tenant_referrals.length}`}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Commision Balance: {toCurrency(selectedUser?.commission_balance)}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Total Payouts: {toCurrency(selectedUser?.total_payouts)}
            </Typography>
          </DialogContent>
          <Box>
            <CustomInputAndLogoField
              label="Amount"
              value={payoutAmount}
              onChange={(e) => setPayoutAmount(e.target.value)}
            />
          </Box>
          <RadioButtonsGroup reason={reason} setReason={setReason} />
          <DialogActions>
            <Button
              variant="outlined"
              disabled={isLoading}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              variant="contained"
              onClick={handlePayout}
              autoFocus
            >
              Pay
            </Button>
          </DialogActions>
          {isLoading ? <CircularProgress /> : null}
        </Box>
      </Dialog>
    </div>
  );
}

export function RadioButtonsGroup({ reason, setReason }) {
  const handleChange = (event) => {
    setReason(event.target.value);
  };
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Reason</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="commission_payout"
        name="radio-buttons-group"
        value={reason}
        onChange={handleChange}
      >
        <FormControlLabel
          value="commission_payout"
          control={<Radio size="small" />}
          label="Commision Payout"
        />
        <FormControlLabel
          value="wallet_withdrawal"
          control={<Radio size="small" />}
          label="No Reason"
        />
      </RadioGroup>
    </FormControl>
  );
}
