/* eslint-disable no-unused-vars */
import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material";

const Container = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default function ControlledCheckbox({ text, defaultChecked, size }) {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Container>
      <Checkbox
        // checked={checked}
        defaultChecked={defaultChecked}
        size={size}
        // onChange={handleChange}
        // inputProps={{ "aria-label": "controlled" }}
      />
      <small>{text}</small>
    </Container>
  );
}
