import { styled } from "@mui/material";
import BackComp from "components/parts/BackComp";
import React from "react";
import { CardWrapper, CustomBtn, FlexCenter } from "shared/components";
import CustomConfigInput from "./CustomConfigInput";

const MonthlyRentConfig = () => {
  return (
    <div style={{ width: "100%" }}>
      <BackComp />
      <p style={{ fontWeight: "bold", textAlign: "center", fontSize: "1rem" }}>
        MONTHLY PLAN CONFIGURATION
      </p>

      <ConfigSection>
        <p>Default Plan</p>

        <ConfigCard>
          <LiveizyConfig>
            <CustomConfigInput label={"Liveizy Contribution"} />
            <CustomConfigInput label={"Liveizy Interest"} />
            <CustomConfigInput label={"Bank Interest"} />
          </LiveizyConfig>

          <APRConfigSection>
            <p>APR (Annual Performance Reward)</p>
            <FlexCenter style={{ justifyContent: "flex-start", gap: "1rem" }}>
              <p style={{}}>Max. APR Increase</p>
              <CustomConfigInput />
            </FlexCenter>
            <APRConfigs>
              <CustomConfigInput label={"Above 90%"} small={true} />
              <CustomConfigInput label={"70%-90%"} small={true} />
              <CustomConfigInput label={"60%-70%"} small={true} />
              <CustomConfigInput label={"50%-60%"} small={true} />
              <CustomConfigInput label={"35%-50%"} small={true} />
              <CustomConfigInput label={"35%  Below"} small={true} />
            </APRConfigs>

            <FlexCenter style={{ gap: "5rem" }}>
              <CustomBtn style={{ width: "100px" }}>BENEFICIARY</CustomBtn>
              <CustomBtn style={{ width: "100px" }}>UPDATE</CustomBtn>
            </FlexCenter>
          </APRConfigSection>
        </ConfigCard>
      </ConfigSection>

      <PlanSection>
        <p>Default Plan</p>
        <PlanCard>
          <p>Delete</p>
          <FlexCenter style={{ height: "100%" }}>
            <CreatePlanBtn>Create New Plan</CreatePlanBtn>
          </FlexCenter>
        </PlanCard>
      </PlanSection>
    </div>
  );
};

export default MonthlyRentConfig;

const ConfigSection = styled("section")`
  display: grid;
  gap: 1rem;
  p {
    color: #052137;
    font-size: 0.9rem;
    font-weight: 600;
  }
`;

const ConfigCard = styled(CardWrapper)`
  display: grid;
  gap: 1rem;
  padding: 2.5rem 2rem;
`;

const LiveizyConfig = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const APRConfigSection = styled("div")`
  display: grid;
`;

const APRConfigs = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
`;

const PlanSection = styled("section")`
  display: grid;
  gap: 1rem;
  p {
    color: #052137;
    font-size: 0.9rem;
    font-weight: 600;
  }
`;

const PlanCard = styled(CardWrapper)`
  height: 350px;
  p {
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const CreatePlanBtn = styled("button")`
  cursor: pointer;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.75);
  background: #fff;
  box-shadow: 0px 4.2992px 31.16924px -8.59841px rgba(0, 0, 0, 0.25);
  width: 250px;
  height: 60px;
`;
