import { styled } from "@mui/material";
import React from "react";

const Container = styled("div")`
  background-color: white;
  display: flex;
  padding: 5px 10px;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 15px;
`;

const Icon = styled("div")`
  color: red;
`;

const Input = styled("input")`
  border: none;
  width: 100%;
  color: black;
  :focus {
    outline: none;
  }
`;

const TextandLogoField = ({
  logo,
  placeholder,
  fieldType,
  onChange,
  value,
}) => {
  return (
    <Container>
      <Icon>{logo}</Icon>
      <Input
        type={fieldType}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </Container>
  );
};

export default TextandLogoField;
