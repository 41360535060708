import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import BackComp from "../../../components/parts/BackComp";
import propertyPicture from "../../../assets/images/propertyimage.png";
import TitleUnderline from "../../../components/parts/TitleUnderline";
import SubtitleAndSubtext from "../../../components/parts/SubtitleAndSubtext";

const PropertyImage = styled("div")`
  width: 200px;
  height: 140px;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const Details = styled("div")`
  display: flex;
  justify-content: flex-start;
`;

const Column = styled("div")`
  margin-right: 50px;
`;

const SmallCard = styled("div")`
  width: 180px;
  height: 120px;
  background-color: #ffff;
  border-radius: 12px;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.45);
  text-align: center;
  p {
    font-weight: bold;
    font-size: 15px;
  }
  h4 {
    font-weight: bold;
    color: #f60000;
    font-size: 30px;
  }
`;

const PropertyView = () => {
  return (
    <Container>
      <BackComp />
      <Box
        display={"flex"}
        gap="20px"
        alignItems={"center"}
        justifyContent="flex-start"
      >
        <PropertyImage>
          <img src={propertyPicture} alt="property image" />
        </PropertyImage>
        <Box>
          <Typography component="h4" fontWeight={"bold"}>
            Property Name
          </Typography>
          <Typography component="p">21 Gbagada Lagos</Typography>
          <Typography component="p">Nigeria</Typography>
        </Box>
      </Box>

      {/* Summary Card */}
      <Box
        marginY={"40px"}
        display={"flex"}
        gap="20px"
        alignItems={"center"}
        justifyContent="flex-start"
      >
        <SmallCard>
          <p>Total Apartments</p>
          <h4>4</h4>
        </SmallCard>
        <SmallCard>
          <p>Occupied Apartments</p>
          <h4>0</h4>
        </SmallCard>
        <SmallCard>
          <p>Vacant Apartments</p>
          <h4>2</h4>
        </SmallCard>
      </Box>

      {/* Summary */}
      <Box>
        <TitleUnderline title={"Property Summary"} />
        {/* Details */}
        <Details>
          <Column>
            <SubtitleAndSubtext
              subtitle={"Property Name"}
              subtext={"Victory Villa"}
            />
            <SubtitleAndSubtext subtitle={"Property Type"} subtext={"duplex"} />
            <SubtitleAndSubtext
              subtitle={"Settlement Type"}
              subtext={"Compound"}
            />
          </Column>
          <Column>
            <SubtitleAndSubtext
              subtitle={"Settlement Numbering System"}
              subtext={"default"}
            />
            <SubtitleAndSubtext subtitle={"House Number"} subtext={"3"} />
            <SubtitleAndSubtext
              subtitle={"Street"}
              subtext={"Mohammed street"}
            />
          </Column>
          <Column>
            <SubtitleAndSubtext subtitle={"State"} subtext={"Lagos"} />
            <SubtitleAndSubtext
              subtitle={"Local Government Area"}
              subtext={"Gbagada"}
            />
            <SubtitleAndSubtext
              subtitle={"Loacal Council Development Area"}
              subtext={"Kosofe"}
            />
          </Column>
          {/* <Column>
            <SubtitleAndSubtext subtitle={"Sex"} subtext={"sex"} />
            <SubtitleAndSubtext subtitle={"Email"} subtext={"email"} />
          </Column> */}
        </Details>
      </Box>
    </Container>
  );
};

export default PropertyView;
