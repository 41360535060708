import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AiOutlineDown } from "react-icons/ai";
import { Button, Container, styled } from "@mui/material";
import TitleUnderline from "../parts/TitleUnderline";

const AccordionDiv = styled(Accordion)`
  margin: 20px auto;
  width: 900px;
  padding: 0px;
  height: auto;
  font-size: 14px;
`;

const AccSummary = styled(AccordionSummary)`
  width: 100%;
  padding: 10px;
  height: 70px;
  margin: auto 0;
  background: white;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const PropertySummary = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
`;

const ApartmentSummary = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 20px auto;
`;

export default function PropertyAccordion() {
  return (
    <Container>
      <div
        className="position-center"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <AccordionDiv>
          <AccSummary
            expandIcon={<AiOutlineDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <PropertySummary>
              <div>
                <h4>
                  <strong>Victory Villa &#40;070458&#41;</strong>
                </h4>
                <p>21, Gbagada street, Lagos state.</p>
              </div>
              <div>
                <p>2 Apartments</p>
              </div>
              <div>
                <Button
                  sx={{
                    width: "220px",
                    height: "40px",
                    fontSize: "11px",
                    color: "white",
                    background: "#10609F",
                  }}
                >
                  View Property
                </Button>
              </div>
            </PropertySummary>
          </AccSummary>
          <AccordionDetails>
            <ApartmentSummary>
              <div>
                <h4>
                  <strong>Apartment 074653-0815-XD</strong>
                </h4>
              </div>
              <div>
                <p>Status</p>
                <small style={{ color: "green" }}>Vacant</small>
              </div>
              <div>
                <p>Current Tenant</p>
                <small>
                  <strong>No Tenant Attached</strong>
                </small>
              </div>
              <div>
                <Button
                  sx={{
                    width: "220px",
                    height: "40px",
                    fontSize: "11px",
                    color: "white",
                    background: "#10609F",
                  }}
                >
                  View Apartment
                </Button>
              </div>
            </ApartmentSummary>
            {/*  */}
            <ApartmentSummary>
              <div>
                <h4>
                  <strong>Apartment 074653-0815-XD</strong>
                </h4>
              </div>
              <div>
                <p>Status</p>
                <small style={{ color: "green" }}>Vacant</small>
              </div>
              <div>
                <p>Current Tenant</p>
                <small>
                  <strong>No Tenant Attached</strong>
                </small>
              </div>
              <div>
                <Button
                  sx={{
                    width: "220px",
                    height: "40px",
                    fontSize: "11px",
                    color: "white",
                    background: "#10609F",
                  }}
                >
                  View Apartment
                </Button>
              </div>
            </ApartmentSummary>
            {/*  */}
            <ApartmentSummary>
              <div>
                <h4>
                  <strong>Apartment 074653-0815-XD</strong>
                </h4>
              </div>
              <div>
                <p>Status</p>
                <small style={{ color: "green" }}>Vacant</small>
              </div>
              <div>
                <p>Current Tenant</p>
                <small>
                  <strong>No Tenant Attached</strong>
                </small>
              </div>
              <div>
                <Button
                  sx={{
                    width: "220px",
                    height: "40px",
                    fontSize: "11px",
                    color: "white",
                    background: "#10609F",
                  }}
                >
                  View Apartment
                </Button>
              </div>
            </ApartmentSummary>
          </AccordionDetails>
        </AccordionDiv>
      </div>
      {/*  */}
      {/*  */}
    </Container>
  );
}
