import React, { useEffect } from "react";
import EmploymentDetails from "../../components/cards/EmploymentDetails";
import PersonalDetails from "../../components/cards/PersonalDetails";
import TenancyDetails from "../../components/cards/TenancyDetails";
import BackComp from "../../components/parts/BackComp";
import TitleUnderline from "../../components/parts/TitleUnderline";
import { useAppContext } from "../../context/context";
import profilePic from "../../mock data/mockDp.png";

const Tenants = () => {
  const { setNavState } = useAppContext();
  useEffect(() => {
    setNavState("USERS - Tenant");
  });
  return (
    <div style={{ width: "100%" }}>
      <BackComp />
      {/* Personal Details */}
      <TitleUnderline title="Personal Details" />
      <PersonalDetails
        bvn="356778997623"
        profilePx={profilePic}
        surname="Aderugba"
        firstName="Jennifer"
        otherName="Jane"
        sex="Femaile"
        dateOfBirth="07-04-1985"
        StateOfOrigin="Lagos State"
        lga="Alimosho"
        email="aderugbajennifer@gmail.com"
        altEmail="aderugbajenny@gmail.com"
        mobile="08123456789"
        altMobile="08123564789"
      />
      {/* Employment Details */}
      <TitleUnderline title="Employment Details" />
      <EmploymentDetails
        employmentStatus={"Employed"}
        officeContactPersonName={"Ade Johnson"}
        employerName={"John Micheal"}
        officeContactPersonNumber={"09016532489"}
        officeAddress={"10 gbagada street, Lagos state."}
        monthlyIncome={"N50,000"}
      />
      {/* Tenancy Details */}
      <TitleUnderline title="Tenancy Details" />
      <TenancyDetails
        currentHomeAddress={"10 gbagada street, lagos state."}
        nameOfManager={"Admon Frank"}
        currentRentValue={"N20,000"}
        contactOfManager={"0218546472"}
        nameOfLandlord={"Ajayi Victory"}
        propertyID={"15468"}
        contactOfLandlord={"0814569782"}
        apartment={"Apartment 44667-456"}
      />
    </div>
  );
};

export default Tenants;
