import { styled } from "@mui/material";
import React from "react";

const Container = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-overflow: ellipsis;
`;

const SubtitleAndSubtext = ({ subtitle, subtext }) => {
  return (
    <Container>
      <small
        style={{
          fontWeight: 500,
          textTransform: "uppercase",
          color: "rgba(5, 33, 55, 0.7)",
        }}
      >
        {subtitle}
      </small>
      <p style={{ fontWeight: "bold", color: "red", fontSize: "1.2rem" }}>
        {subtext}
      </p>
    </Container>
  );
};

export default SubtitleAndSubtext;
