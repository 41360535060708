import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { useEffect } from "react";
import { authService } from "apis";
import { CircularProgress } from "@mui/material";

const LatestUsersTable = ({ latestUsers, loading }) => {
  return (
    <TableContainer component={Paper} sx={{ height: "300px" }}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        {loading ? (
          <CircularProgress sx={{ margin: "40%" }} />
        ) : (
          <TableBody>
            {latestUsers.map((user) => {
              const dateJoined = new Date(user.created_at).toLocaleDateString();
              const timeJoined = new Date(user.created_at).toLocaleTimeString();
              return (
                <TableRow key={user.id} sx={{ border: 0, color: "#052137" }}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                    component="th"
                    scope="row"
                  >
                    {user.first_name} {user.last_name}
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", fontSize: "10px" }}
                    align="right"
                  >
                    {timeJoined}
                  </TableCell>
                  <TableCell sx={{ fontSize: "10px" }} align="right">
                    {dateJoined}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default LatestUsersTable;
