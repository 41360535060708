import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import LoginPage from "./pages/auth/Login";
import SharedLayout from "./components/dashboards/SharedLayout";
import Property from "./pages/Property/Index";
import Users from "./pages/Users/Index";
import Dashboard from "./pages/dashboard/Index";
import ProtectedRoute from "./pages/ProtectedRoute";
import Tenants from "./pages/Users/Tenants";
import ManagersAndLandlords from "./pages/Users/ManagersAndLandlords";
import PropertyReview from "./pages/Property/PropertyReview";
import VerifyStageOneView from "./pages/Property/rent-management/monthly-rent/stageOne/VerifyStageOneView";
import MonthlyRent from "./pages/Property/rent-management/monthly-rent/MonthlyRent";
import VerifyStageTwo from "./pages/Property/rent-management/monthly-rent/stageTwo/VerifyStageTwo";
import UserView from "./pages/Users/UserView";
import PropertyView from "./pages/Users/views/PropertyView";
import ApartmentView from "./pages/Users/views/ApartmentView";
import Referral from "features/wallet/referral/Referral";
import IzyInvest from "features/wallet/izyinvest/IzyInvest";
import MonthlyRentTenantView from "pages/Property/rent-management/monthly-rent/activeStage/MonthlyRentTenantView";
import MonthlyRentConfig from "pages/Property/rent-management/monthly-rent/config/MonthlyRentConfig";
import useClearCacheOnMount from "utils/clearCache";
import LoanRenewalsView from "pages/Property/rent-management/monthly-rent/activeStage/LoanRenewalsView";

function App() {

  useClearCacheOnMount();

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.login} element={<LoginPage />} />
          <Route path={"/"} element={<LoginPage />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<SharedLayout />}>
              <Route path="dashboard" element={<Dashboard />} />

              {/* Users */}
              <Route path={ROUTES.users}>
                <Route index element={<Users />} />
                <Route path="tenant" element={<Tenants />} />
                <Route path="view/:liveizy_id" element={<UserView />} />
                <Route
                  path="managersandlandlords"
                  element={<ManagersAndLandlords />}
                />
              </Route>

              {/* Property */}
              <Route path={ROUTES.property}>
                <Route index element={<Property />} />
                {/* <Route index element={<MonthlyRent />} /> */}
                {/* Rent Management */}
                <Route path="monthly-rent">
                  <Route index element={<MonthlyRent />} />
                  <Route
                    path="verify-stageone/:liveizy_id"
                    element={<VerifyStageOneView />}
                  />
                  <Route
                    path="verify-stagetwo/:liveizy_id"
                    element={<VerifyStageTwo />}
                  />
                  <Route
                    path="active/:liveizy_id/:apartment_id"
                    element={<MonthlyRentTenantView />}
                  />
                  <Route
                    path="renewals/:liveizy_id/:apartment_id"
                    element={<LoanRenewalsView />}
                  />
                  <Route path="config" element={<MonthlyRentConfig />} />
                </Route>
                {/* View */}
                <Route path="view">
                  <Route path="property" element={<PropertyView />} />
                  <Route path="apartment" element={<ApartmentView />} />
                </Route>
              </Route>
              <Route
                path="/dashboard/property/propertyreview"
                element={<PropertyReview />}
              />
              <Route
                path={ROUTES.addAdmin}
                element={<ManagersAndLandlords />}
              />
              <Route path="wallet">
                <Route path={"referral"} element={<Referral />} />
                <Route path={"izyinvest"} element={<IzyInvest />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <Router>
        <Switch>
          <Route path={ROUTES.login} exact component={LoginPage} />
          <Route path={ROUTES.index} exact component={LoginPage} />
          <Route
            path={[
              ROUTES.dashboardUsers,
              ROUTES.dashboardPayments,
              ROUTES.dashboardPlans,
              ROUTES.dashboardCourses,
              ROUTES.estateLists,
              ROUTES.superAdmin,
            ]}
            exact
          >
            <Dashboard>
              <Switch>
                <Route path={ROUTES.dashboardPayments} exact component={Payments} />
                <Route path={ROUTES.dashboardPlans} exact component={Plans} />
                <Route path={ROUTES.dashboardUsers} exact component={Users} />
                <Route path={ROUTES.estateLists} exact component={Estate} />
                <Route path={ROUTES.superAdmin} exact component={SuperAdmin} />
              </Switch>
            </Dashboard>
          </Route>
          {/*<Route path="/" component={NotFoundPage} />*/}
      {/* </Switch> */}
      {/* </Router> */}
    </div>
  );
}

export default App;
