import { Button, styled } from "@mui/material";

const CustomBtn = styled(Button)`
  background: #10609f;
  color: white;
  font-size: 12px;

  :hover {
    background: #0d4a79;
    opacity: 1;
    box-shadow: inset;
  }
`;

export default CustomBtn;
