import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { izypromanService } from "../../../apis";
import PropertyAccordion from "../../../components/accordions/PropertyAccordion";
import TitleUnderline from "../../../components/parts/TitleUnderline";

const PropertyComponent = ({ lid }) => {
  const [propertyList, setPropertyList] = useState([]);

  useEffect(() => {
    izypromanService()
      .get(`/property/ownerProperties/${lid}`)
      .then((res) => {
        setPropertyList(res.data.payload);
      });
  }, [lid]);
  return (
    <Container>
      <TitleUnderline title="Properties Owned" />
      {propertyList.length > 0 &&
        propertyList.map((property, index) => {
          return <PropertyAccordion property={property} key={index} />;
        })}
    </Container>
  );
};

export default PropertyComponent;
