import { styled } from "@mui/material";

const Container = styled("div")`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  background: white;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
  border-radius: 29.4316px;
  padding: 15px;
  margin-bottom: 10px;
`;

const DetailsCardContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default DetailsCardContainer;
