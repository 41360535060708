import { useEffect } from "react";
import PropertyAccordion from "../../components/accordions/PropertyAccordion";
import PersonalDetails from "../../components/cards/PersonalDetails";
import BackComp from "../../components/parts/BackComp";
import TitleUnderline from "../../components/parts/TitleUnderline";
import profilePic from "../../mock data/mockDp.png";
import { useAppContext } from "../../context/context";

const ManagersAndLandlords = () => {
  const { setNavState } = useAppContext();
  useEffect(() => {
    setNavState("USERS - Managers/Landlord");
  });
  return (
    <div style={{ width: "100%" }}>
      <BackComp />
      {/* Personal Details */}
      <TitleUnderline title="Personal Details" />
      <PersonalDetails
        bvn="356778997623"
        profilePx={profilePic}
        surname="Aderugba"
        firstName="Jennifer"
        otherName="Jane"
        sex="Femaile"
        dateOfBirth="07-04-1985"
        StateOfOrigin="Lagos State"
        lga="Alimosho"
        email="aderugbajennifer@gmail.com"
        altEmail="aderugbajenny@gmail.com"
        mobile="08123456789"
        altMobile="08123564789"
      />
      {/* Properties Owned */}
      <TitleUnderline title="Properties Owned" />
      <PropertyAccordion />
    </div>
  );
};

export default ManagersAndLandlords;
