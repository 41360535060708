/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useAppContext } from "../../context/context";
import {
  Container,
  Card,
  AwaitingListBody,
  ViewButton,
} from "./propertyStyles";
import styles from "./property.module.css";

const PropertyReview = () => {
  const { setNavState } = useAppContext();
  useEffect(() => {
    setNavState("PROPERTY");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <Card>
        <p className={styles.awaitingListing}>
          {" "}
          Total awaiting listing reviews{" "}
        </p>
        <h1 className={styles.awaitingListingCount}>30</h1>
      </Card>
      <AwaitingListBody>
        <table className={styles.awaitingListTable}>
          <tr className={styles.tableRow}>
            <th>Property ID</th>
            <th>Apartment ID</th>
            <th>Owners ID</th>
            <th>Location</th>
            <th></th>
          </tr>

          <tr>
            <td>234567</td>
            <td>529978-8965-SD</td>
            <td>UDbg1234</td>
            <td>Lagos state</td>
            <td>
              <ViewButton> View </ViewButton>
            </td>
          </tr>

          <tr>
            <td>234567</td>
            <td>529978-8965-SD</td>
            <td>UDbg1234</td>
            <td>Lagos state</td>
            <td>
              <ViewButton> View </ViewButton>
            </td>
          </tr>

          <tr>
            <td>234567</td>
            <td>529978-8965-SD</td>
            <td>UDbg1234</td>
            <td>Lagos state</td>
            <td>
              <ViewButton> View </ViewButton>
            </td>
          </tr>

          <tr>
            <td>234567</td>
            <td>529978-8965-SD</td>
            <td>UDbg1234</td>
            <td>Lagos state</td>
            <td>
              <ViewButton> View </ViewButton>
            </td>
          </tr>
        </table>
      </AwaitingListBody>
    </Container>
  );
};

export default PropertyReview;
